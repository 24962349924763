<div class="navigation-container">
    <div class="navigation-basic">
        <div class="content">
            <!-- Small Navigation -->
            <div class="nav-min">
                <div class="nav-img" (click)="dropDownNavModal = true" (clickOutside)="dropDownNavModal = false"></div>
                <div class="dropdown-nav" *ngIf="dropDownNavModal">
                    <div class="dropdown-list">
                        <div class="dropdown-item" routerLink="/offers" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer', 'SupplierAdmin' ,'Supplier', 'Buyer']"
                            (click)="reset()">
                            Offers
                        </div>
                        <div class="dropdown-item" routerLink="/suggestions" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator']" (click)="reset();routeToAllSuggestion()">
                            Suggestions
                        </div>
                        <div class="dropdown-item" routerLink="/categories" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator', 'Buyer']" (click)="reset()">
                            Categories
                        </div>
                        <div class="dropdown-item" routerLink="/collections" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator', 'Buyer']" (click)="reset()">
                            Collections
                        </div>
                        <div class="dropdown-item" routerLink="/focal-points" authorized [forRoles]="['SupplierAdmin']"
                            (click)="reset()">
                            Focal Points
                        </div>
                        <div class="dropdown-item" routerLink="/surveys/admin" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator']" (click)="reset()">
                            Surveys
                        </div>
                        <div class="dropdown-item" routerLink="/roadshows-administration" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin', 'Supplier', 'RoadshowFocalPoint']"
                            (click)="reset()">
                            Roadshows
                        </div>
                        <div class="dropdown-item" routerLink="/roadshows" authorized [forRoles]="['Buyer']"
                            (click)="reset()">
                            Roadshows
                        </div>
                        <div class="dropdown-item" routerLink="/administration" authorized
                            [forRoles]="['Admin', 'AdnocCoordinator']" (click)="reset()">
                            Administration
                        </div>



                        <div class="dropdown-item" routerLink="/profile" authorized [forRoles]="['SupplierAdmin']"
                            (click)="reset()">
                            Profile
                        </div>
                        <div class="dropdown-item" routerLink="/surveys/my-survey" authorized
                            [forRoles]="[ 'SupplierAdmin' ,'Supplier', 'Buyer']" (click)="reset()">
                            Surveys
                        </div>
                        <div class="dropdown-item" (click)="logOut()">
                            Log Out
                        </div>
                    </div>
                </div>
            </div>
            <!-- User -->
            <div class="user">
                <img class="user-img" [defaultImage]="'assets/images/default/user_def.svg'"
                    [errorImage]="'assets/images/default/user_def.svg'" [lazyLoad]="profilePicture | img"
                    (onStateChange)="userProfileImageState($event)" (click)="dropDownUserModal = true"
                    (clickOutside)="dropDownUserModal = false">
                <div class="dropdown-user" *ngIf="dropDownUserModal">
                    <div class="dropdown-list">
                        <div class="dropdown-item" routerLink="/my-profile">
                            Profile
                        </div>
                        <div class="dropdown-item" (click)="logOut()">
                            Log Out
                        </div>
                    </div>
                </div>
            </div>
            <div class="break"></div>
            <div class="nav-items"
                [class.nav-item-admin]="role == 'Admin' || role == 'AdnocCoordinator' || role=='NirvanaFinance'">
                <div class="nav-item" routerLink="/profile" authorized [forRoles]="['SupplierAdmin']"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Profile
                </div>
                <div class="nav-item" routerLink="/offers" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer', 'SupplierAdmin' ,'Supplier', 'Buyer',]"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Offers
                </div>

                <div class="nav-item" routerLink="/dashboard" authorized [forRoles]="['NirvanaFinance']"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Dashboard
                </div>
                <div class="nav-item" routerLink="/card-update" authorized [forRoles]="['MazayaSupport']"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Card Update
                </div>
                <div class="nav-item" routerLink="/add-pioneer" authorized [forRoles]="['MazayaSupport']"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Add Pioneer
                </div>

                <!-- companyconfiguration -->
                <div class="nav-item" routerLink="/mazaya-plusplus" authorized [forRoles]="['MazayaSupport']"
                    [routerLinkActive]="['is-active']" (click)="reset()">
                    Mazaya ++
                </div>

                <!-- companyconfiguration -->
                <div class="nav-item" routerLink="/suggestions" authorized [forRoles]="['Admin', 'AdnocCoordinator']"
                    [routerLinkActive]="['is-active']" (click)="reset();routeToAllSuggestion()">
                    Suggestions
                </div>
                <div class="nav-item" routerLink="/suppliers" authorized [forRoles]="['Admin', 'AdnocCoordinator']"
                    [routerLinkActive]="['is-active']">
                    Partners</div>
                <div id="tabCategories" class="nav-item nav-item-categories" routerLink="/categories" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'Buyer']" [routerLinkActive]="['is-active']">Categories
                </div>
                <div id="tabCollections" class="nav-item nav-item-collections" routerLink="/collections" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'Buyer']" [routerLinkActive]="['is-active']">Collections
                </div>
                <div class="nav-item" routerLink="/focal-points" authorized [forRoles]="['SupplierAdmin']"
                    [routerLinkActive]="['is-active']" (click)="reset()">Focal Points</div>
                <div class="nav-item" routerLink="/surveys/admin" authorized [forRoles]="[ 'Admin', 'AdnocCoordinator']"
                    [routerLinkActive]="['is-active']" (click)="reset()">Surveys</div>
                <div class="nav-item" routerLink="/roadshows-administration" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin', 'Supplier', 'RoadshowFocalPoint']"
                    [routerLinkActive]="['is-active']" (click)="reset()">Roadshows</div>
                <div class="nav-item" routerLink="/roadshows" authorized [forRoles]="['Buyer']"
                    [routerLinkActive]="['is-active']" (click)="reset()">Roadshows</div>
                <div id="tabAdministration" class="nav-item" routerLink="/administration" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator','NirvanaFinance']" [routerLinkActive]="['is-active']"
                    (click)="reset()">
                    Administration</div>

                <div class="nav-item" routerLink="/surveys/my-survey" authorized
                    [forRoles]="[ 'SupplierAdmin' ,'Supplier', 'Buyer']" [routerLinkActive]="['is-active']"
                    (click)="reset()">Surveys</div>
                <div class="nav-item" routerLink="/mazaya-plus" authorized [forRoles]="['Buyer']"
                    [routerLinkActive]="['is-active']" (click)="reset()" *ngIf="isMazayaEligible">Mazaya++</div>

                <div class="nav-item" routerLink="/my-mazaya-plus" authorized [forRoles]="['Buyer']"
                    [routerLinkActive]="['is-active']" (click)="reset()" *ngIf="membercardcount">My Mazaya++</div>
            </div>


            <div class="search-container">
                <div class="search-icon" (click)="search()"></div>
                <input id="search" class="search-input" type="text" [placeholder]=searchTextPlaceholder
                    [(ngModel)]="searchText" (keydown.enter)="search()">
                <div class="search-reset" *ngIf="searchText.length > 0" (click)="searchReset()"></div>
            </div>
            <div class="break"></div>
            <div class="logo-offers" [routerLink]="'/'"></div>
            <app-user-notifications></app-user-notifications>
        </div>
    </div>


    <!-- Companies - sub nav -->
    <div class="navigation-sub" *ngIf="activeNav == 'companies'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <div class="nav-sub-item" routerLink="/companies/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator']" (click)="reset()">
                    All Companies
                </div>
                <div class="nav-sub-item" routerLink="/companies/pending" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator']" (click)="reset()">
                    Pending Companies
                </div>
            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>

    <!-- Offers - sub nav -->
    <div class="navigation-sub" *ngIf="activeNav == 'offers'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <div class="nav-sub-item" routerLink="/offers/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer', 'SupplierAdmin' ,'Supplier', 'Buyer']"
                    (click)="reset()">
                    All Offers
                </div>
                <div class="nav-sub-item" routerLink="/offers/favorites" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Buyer']" (click)="reset()">
                    Favorites
                </div>
                <div class="nav-sub-item" routerLink="/offers/my-offers" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer', 'SupplierAdmin' ,'Supplier']"
                    (click)="reset()">
                    My Offers
                </div>
                <div class="nav-sub-item" routerLink="/offers/assigned" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer']" (click)="reset()">
                    Assigned Offers <span authorized [forRoles]="['Admin', 'AdnocCoordinator']"
                        *ngIf="assignedOffersCount > 0">({{assignedOffersCount}})</span>
                </div>
                <div class="nav-sub-item" routerLink="/offers/reported" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'Reviewer']" (click)="reset()">
                    Reported Offers <span authorized [forRoles]="['Admin', 'AdnocCoordinator']"></span>
                </div>
                <!-- <div class="nav-sub-item" routerLink="/offers/reported" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator' ]">
                    Reported
                </div> -->
            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>

    <!-- Suppliers - sub nav -->
    <div class="navigation-sub" *ngIf="activeNav == 'suppliers'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <div class="nav-sub-item" routerLink="/suppliers/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin']" (click)="reset()">
                    All Partners
                </div>
                <div class="nav-sub-item" routerLink="/suppliers/pending" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin']" (click)="reset()">
                    Pending Partners
                </div>
                <div class="nav-sub-item" routerLink="/suppliers/inactive" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin']" (click)="reset()">
                    Inactive Partners
                </div>
                <div class="nav-sub-item" routerLink="/suppliers/missing" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin']" (click)="reset()">
                    Missing License Partners
                </div>
            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>
    <div class="navigation-sub" *ngIf="activeNav == 'suggestions'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div #navSub class="nav-sub-items">
                <div class="nav-sub-item" [routerLink]="['/suggestions/all']"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['Admin', 'AdnocCoordinator']">
                    All Suggestions
                </div>
                <div class="nav-sub-item" [routerLink]="['/suggestions/complete']"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['Admin', 'AdnocCoordinator']">
                    Complete Suggestions
                </div>
                <div class="nav-sub-item" [routerLink]="['/suggestions/incomplete']"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['Admin', 'AdnocCoordinator']">
                    Incomplete Suggestions
                </div>
            </div>
        </div>
    </div>
    <!-- Roadshows Administration - sub nav -->
    <div class="navigation-sub" *ngIf="activeNav == 'roadshows-administration'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <!-- <div class="nav-sub-item" routerLink="/roadshows-administration/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin' ,'Supplier', 'Buyer']">
                    All
                </div> -->
                <div class="nav-sub-item" routerLink="/roadshows-administration/planning"
                    [routerLinkActive]="['nav-sub-item-active']" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin' ,'Supplier', 'RoadshowFocalPoint']"
                    (click)="reset()">
                    Planning
                </div>
                <div class="nav-sub-item" routerLink="/roadshows-administration/planning-calendar"
                    [routerLinkActive]="['nav-sub-item-active']" authorized
                    [forRoles]="['Admin', 'AdnocCoordinator', 'RoadshowFocalPoint']" (click)="reset()">
                    Calendar
                </div>
                <div class="nav-sub-item" routerLink="/roadshows-administration/offers"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['SupplierAdmin' ,'Supplier']"
                    (click)="reset()">
                    Offers
                </div>
            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>

    <div class="" *ngIf="activeNav == 'mazaya-plus'">
        <div class="content">

        </div>
    </div>


    <!-- <div class="" *ngIf="activeNav == 'dashboard'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
        </div>
    </div> -->

    <div class="" *ngIf="activeNav == 'card-update'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
        </div>
    </div>
    <div class="" *ngIf="activeNav == 'add-pioneer'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
        </div>
    </div>
    <div class="" *ngIf="activeNav == 'company-configuration'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
        </div>
    </div>

    <div class="navigation-sub" *ngIf="activeNav == 'my-mazaya-plus'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <!-- <div class="nav-sub-item" routerLink="/roadshows-administration/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin' ,'Supplier', 'Buyer']">
                    All
                </div> -->
                <div class="nav-sub-item" routerLink="/my-mazaya-plus/my-cards"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['Buyer']" (click)="reset()">
                    Mazaya++ Cards
                </div>
                <div class="nav-sub-item" routerLink="/my-mazaya-plus/transactions" *ngIf="isMazayaAdmin"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['Buyer']" (click)="reset()">
                    Transactions
                </div>

            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>

    <!-- companyconfiguration -->
    <div class="navigation-sub" *ngIf="activeNav == 'mazaya-plusplus'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">

                <!-- <div class="nav-sub-item" routerLink="/mazaya-plusplus/company-configuration"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['MazayaSupport']"
                    (click)="reset()">
                    Company Configuration
                </div> -->
                <!-- <div class="nav-sub-item" routerLink="/mazaya-plusplus/complimentary-card"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['MazayaSupport']"
                    (click)="reset()">
                    Complimentary Card
                </div> -->
                <div class="nav-sub-item" routerLink="/mazaya-plusplus/company-main-card"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['MazayaSupport']"
                    (click)="reset()">
                    Company Paid Main Card
                </div>

            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>
    <!-- Roadshows Buyer - sub nav -->
    <div class="navigation-sub" *ngIf="activeNav == 'roadshows'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <!-- <div class="nav-sub-item" routerLink="/roadshows-administration/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin', 'AdnocCoordinator', 'SupplierAdmin' ,'Supplier', 'Buyer']">
                    All
                </div> -->
                <div class="nav-sub-item" routerLink="/roadshows/all" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Buyer']" (click)="reset()">
                    Roadshow Locations
                </div>
            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>
    <!--Survey  all-->
    <div class="navigation-sub" *ngIf="activeNav == 'surveys'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <div class="nav-sub-item" routerLink="/surveys/admin" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['Admin' ,'AdnocCoordinator']" (click)="reset()">
                    All Surveys
                </div>
                <div class="nav-sub-item" routerLink="/surveys/my-survey" [routerLinkActive]="['nav-sub-item-active']"
                    authorized
                    [forRoles]="['Admin' ,'AdnocCoordinator','Reviewer', 'SupplierAdmin' ,'Supplier','Buyer']"
                    (click)="reset()">
                    My Surveys
                </div>

            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>
    <!-- Adminitration - sub nav -->
    <div class="navigation-sub navigation-sub--resp" *ngIf="activeNav == 'administration'">
        <div class="content">
            <div class="home" [routerLink]="['/']"></div>
            <div class="left-scroll" (mouseenter)="moveToLeftNavSub()" (mouseleave)="stopMoveNavSub()"></div>
            <div #navSub class="nav-sub-items">
                <div class="home" [routerLink]="['/']"></div>
                <div id="tabBuyers" class="nav-sub-item" routerLink="/administration/buyers"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Members
                </div>
                <!-- <div class="nav-sub-item" routerLink="/administration/adnoc-users"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']" (click)="reset()">
                    Adnoc Users
                </div> -->

                <div class="nav-sub-item" routerLink="/administration/tags" [routerLinkActive]="['nav-sub-item-active']"
                    authorized [forRoles]="['AdnocCoordinator', 'Admin']" (click)="reset()">
                    Tags
                </div>
                <div class="nav-sub-item" routerLink="/administration/banners"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Banners
                </div>
                <div class="nav-sub-item" routerLink="/administration/vouchers"
                [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                (click)="reset()">
                Vouchers
            </div>
                <div class="nav-sub-item" routerLink="/administration/offers-rating"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Rating
                </div>
                <div class="nav-sub-item" routerLink="/administration/terms-and-conditions"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    T&C
                </div>
                <div class="nav-sub-item" routerLink="/administration/default-locations"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Default Locations
                </div>
                <div class="nav-sub-item" routerLink="/administration/default-areas"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Default Areas
                </div>
                <div class="nav-sub-item" routerLink="/administration/email-template"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Email Template
                </div>
                <div class="nav-sub-item" routerLink="/administration/email-template-root"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Email Template Root
                </div>
                <div class="nav-sub-item" routerLink="/administration/mail-storage"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Mail Storage
                </div>
                <div class="nav-sub-item" routerLink="/administration/transfer-offers"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Transfer
                </div>
                <div class="nav-sub-item" routerLink="/administration/user-invitation"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Member Invitation
                </div>
                <div class="nav-sub-item" routerLink="/administration/domains"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Domains
                </div>
                <div class="nav-sub-item" routerLink="/administration/inspect"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Inspect
                </div>
                <div class="nav-sub-item" routerLink="/administration/push-notifications"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Push Notifications
                </div>
                <div class="nav-sub-item" routerLink="/administration/supplier-announcement"
                    [routerLinkActive]="['nav-sub-item-active']" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    (click)="reset()">
                    Announcement
                </div>
                <div class="nav-sub-item" routerLink="/administration/analytics"
                    [routerLinkActive]="['nav-sub-item-active']" authorized
                    [forRoles]="['AdnocCoordinator', 'Admin','NirvanaFinance']" (click)="reset()">
                    Analytics
                </div>
                <div class="nav-sub-item" routerLink="/administration/mazaya-plus-report"
                    [routerLinkActive]="['nav-sub-item-active']" authorized
                    [forRoles]="['AdnocCoordinator', 'Admin','NirvanaFinance']" (click)="reset()">
                    Mazaya Plus Report
                </div>


                <div *ngIf="userEmail === 'tamer.thabet@ntravel.ae'" class="nav-sub-item"
                    routerLink="/administration/mazaya-cards" authorized [forRoles]="['AdnocCoordinator', 'Admin']"
                    [routerLinkActive]="['nav-sub-item-active']" (click)="reset()">
                    Mazaya ++
                </div>

            </div>
            <div class="right-scroll" (mouseenter)="moveToRightNavSub()" (mouseleave)="stopMoveNavSub()"></div>
        </div>
    </div>

</div>