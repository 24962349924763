<div class="banners-home-container">
    <div class="carousel" #carouselbanner>
        <div class="carousel_page" *ngFor="let banner of banners; let i = index">
            <div class="banner-card">
                <div class="text-section" (click)="clickBanner(banner.offerId)">
                    <div class="banner__tag" *ngIf="banner.tagTitle">{{banner.tagTitle}}</div>
                    <div class="banner__content">
                        <div class="banner__content__discount">

                            <ng-container *ngIf="banner?.priceType == 'Price'">
                                <span class="discount--range">
                                    <div class="from">From AED {{banner.priceFrom | number: '1.0' }}</div>
                                    <div class="to">To AED {{banner.priceTo | number: '1.0'}}</div>
                                </span>
                            </ng-container>

                            <ng-container *ngIf="banner?.priceType == 'DiscountPrice'">
                                <span class="discount--new">
                                    <span class="old" *ngIf="banner.originalPrice">AED {{banner.originalPrice | number:
                                        '1.0' }}</span>
                                    <span class="new">AED {{banner.discountedPrice | number: '1.0' }}</span>
                                </span>
                            </ng-container>

                            <ng-container
                                *ngIf="banner?.priceType == 'Discount' && banner?.discountFrom && banner?.discountTo">
                                <span class="discount--range">
                                    <div class="from">From {{banner.discountFrom | number: '1.0'}}%</div>
                                    <div class="to">To {{banner.discountTo | number: '1.0'}}%</div>
                                </span>
                            </ng-container>

                            <ng-container *ngIf="banner?.priceType == 'Discount' && banner?.discount">
                                <span class="discount--off">
                                    {{banner.discount | number: '1.0'}}% OFF
                                </span>
                            </ng-container>

                            <ng-container *ngIf="banner?.priceType == 'Other'">
                                {{banner.priceCustom }}
                            </ng-container>

                        </div>
                        <div class="banner__content__title">{{banner.brandTitle}}</div>
                    </div>
                    <div *ngIf="false" class="banner__btn_c">
                        <div class="banner__btn" (click)="clickBanner(banner.offerId)">
                            More info
                        </div>
                    </div>
                </div>
                <div class="big-imgs-section" (click)="clickBanner(banner.offerId)">
                    <img class="def" src="assets/images/default/img_def.svg">
                    <img class="img" (load)="bannerIsReady(i)" [src]="loadImg(i, banner.imageUrls[0].large)">
                </div>
                <div class="small-imgs-section" (click)="clickBanner(banner.offerId)">
                    <div class="small-img">
                        <img class="img" *ngIf="!banner?.imageUrls[1]" src="assets/images/default/img_def.svg">
                        <img class="img" *ngIf="banner?.imageUrls[1]" [src]="loadImg(i, banner.imageUrls[1].thumbnail)">
                    </div>
                    <div class="small-img">
                        <img class="img" *ngIf="!banner?.imageUrls[2]" src="assets/images/default/img_def.svg">
                        <img class="img" *ngIf="banner?.imageUrls[2]" [src]="loadImg(i, banner.imageUrls[2].thumbnail)">
                    </div>
                    <div class="small-img">
                        <img class="img" *ngIf="!banner?.imageUrls[3]" src="assets/images/default/img_def.svg">
                        <img class="img" *ngIf="banner?.imageUrls[3]" [src]="loadImg(i, banner.imageUrls[3].thumbnail)">
                    </div>
                    <div class="small-img">
                        <img class="img" *ngIf="!banner?.imageUrls[4]" src="assets/images/default/img_def.svg">
                        <img class="img" *ngIf="banner?.imageUrls[4]" [src]="loadImg(i, banner.imageUrls[4].thumbnail)">
                    </div>
                </div>
                <div class="website-btn" *ngIf="banner?.bannerUrl" (click)=redirect(banner.offerId)></div>
            </div>
        </div>
    </div>
    <div class="carousel-pagination">
        <div class="page" *ngFor="let page of numberToArray(carouselPageNumber); let i = index"
            [class.page-active]="corouselPageCurent == i + 1" (click)="clickPage(i + 1)">
        </div>
    </div>
</div>