import { Logout } from './../state/auth-state-model';
import { AdnocUsersService } from './../shared/services/adnoc-users.service';
import { Store } from '@ngxs/store';
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthState } from '../state';
import * as _ from "lodash";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styles: [],
})
export class LayoutComponent implements OnInit {
  role: string;
  constructor(
    private _router: Router,
    private store$: Store,
    private adnocUsersService: AdnocUsersService) { }

  ngOnInit() {
    this.scrollToTop();
    this.initRole();
    // this.checkToken();
  }

  // checkToken() {
  //   this.store$.select(AuthState.token).subscribe((res) => {
  //     if (!res || _.isEmpty(res)) {
  //       this.store$.dispatch(new Logout());
  //     }
  //   });
  // }

  initRole(): void {
    this.store$.select(AuthState.roles).subscribe((res) => {
      if (res[0]) {
        this.adnocUsersService.clientsPhotoChanged.emit();
      }
      this.role = res[0];
    });
  }

  scrollToTop() {
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
