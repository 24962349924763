import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TermsAndConditionService } from 'src/app/shared/services/terms-and-condition.service';
import { TermsAndConditionsModel } from 'src/app/shared/models/terms-and-conditions/terms-and-conditions';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styles: []
})
export class TermsModalComponent implements OnDestroy {

  @Output() closeTermsModalE: EventEmitter<any> = new EventEmitter();

  modalTerms: boolean = true;
  modalTermsTab: string = 'en';
  termsAndConditions: TermsAndConditionsModel;
  text: string;

  constructor(private _termsAndConditionsService: TermsAndConditionService) {
    this._termsAndConditionsService.getGlobalTermsAndConditions().subscribe((res) => {
      this.termsAndConditions = res;
      this.text = this.termsAndConditions.content;
    });
  }

  ngOnDestroy(): void {
    this.modalTerms = false;
  }

  toggleModalTermsTab(tab: string): void {
    this.modalTermsTab = tab;
    if (tab === 'en') {
      this.text = this.termsAndConditions.content;
    } else {
      this.text = this.termsAndConditions.contentArabic;
    }
  }

  closeModal() {
    this.closeTermsModalE.emit();
  }
}
