import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class MainErrorHandler implements ErrorHandler {
  handleError(error: any) {

    // Make sure to rethrow the error so Angular can pick it up
    throw error;
  }
}
