import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages";
import { HomeSupplierAdminComponent } from "./pages/home/home-supplier-admin/home-supplier-admin.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "supplier-admin",
    component: HomeSupplierAdminComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule { }
