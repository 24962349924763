import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(private store: Store, private _router: Router) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // allow anonymus request
    if (request.headers.get('Allow-Anonymous') === 'true') {
      return next.handle(request.clone());
    }

    // add token if there is one
    const token = this.store.selectSnapshot(AuthState.token);

    if (!token || isEmpty(token)) {
      // this._router.navigate(['/login']);
      return next.handle(request.clone());
    }

    if (!token) {
      return next.handle(request.clone());
    }

    const modifiedRequest = request.clone({
      headers: request.headers.set('Authorization', token ? `Bearer ${token}` : '')
    });

    return next.handle(modifiedRequest);
  }
}
