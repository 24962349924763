<div class="spinner-container">
  <div class="spinner-content">
    <div class="spinner-icon-container">
      <div class="la-line-scale-pulse-out la-2x">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>