import { FilterDataModel } from "../models/filters/filterDataModel";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})

export class FiltersService {
  constructor(private http: HttpClient) { }
  private resource = `${environment.serverUrl}/filters`;
  private userInfo = `${environment.serverUrl}/mobile/userinfo`;
  
  getFilterData(): Observable<FilterDataModel> {
    return this.http.get<FilterDataModel>(this.resource);
  }
  getUserInfo(): Observable<any> {
    const url = `${this.userInfo}`;
    return this.http.get<any>(url);
  }
}