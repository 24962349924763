import { IdentityProviderInfo } from "../core/models/identity-provider-info";

export interface SettingsStateModel {
  allowImageDeferLoad: boolean;
  identityProvidersInfo: IdentityProviderInfo[];
}

export class CheckIsImageDeferLoadAllowed {
  static readonly type = "[App-Settings] CheckIsImageDeferLoadAllowed";
  constructor() { }
}

export class SaveIdentityProvidersConfiguration {
  static readonly type = "[App-Settings] SaveIdentityProvidersConfiguration";
  constructor(public identityProvidersInfo: IdentityProviderInfo[]) { }
}
