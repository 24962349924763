import { CompaniesService } from 'src/app/shared/services/companies.service';
import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CompanyRegistrationModel } from "src/app/shared/models/company-registration/company-registration.model";

@Component({
  selector: "app-company-register-main",
  templateUrl: "./company-register-main.component.html",
  styleUrls: [],
})
export class CompanyRegisterMainComponent {
  formCompanyData: UntypedFormGroup;
  formAgreement: UntypedFormGroup;
  isFormView: boolean = true;
  companyData: CompanyRegistrationModel;

  constructor(
    private fb: UntypedFormBuilder,
    private companiesService: CompaniesService,
    private router: Router
  ) {
    this.createForms();
  }


  createForms() {
    this.formCompanyData = this.fb.group({
      tradeLicence: ["", Validators.required],
      tradeLicenceExpiryDate: ["", Validators.required],
    });

    this.formAgreement = this.fb.group({
      termsAndConditions: [false, Validators.requiredTrue],
    });
  }

  resetForm() {
    this.formCompanyData.reset();
    this.formAgreement.reset();
  }

  registerCompany() {
    this.companyData = {
      ...this.formCompanyData.getRawValue(),
      ...this.formAgreement.getRawValue(),
    };

    this.companiesService.registerCompany(this.companyData).subscribe(() => {
      this.resetForm();
      this.router.navigate(["registration/custom-registration-info"]);
    });

  }
}
