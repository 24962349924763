import { Directive, Input, ElementRef, OnInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';

@Directive({
  selector: '[modal]'
})
export class ModalDirective implements OnInit, OnDestroy {
  @Input() modalActive: boolean;

  ngOnInit(): void {
    document.body.classList.add("stop-scroll");
  }
  ngOnDestroy(): void {
    document.body.classList.remove("stop-scroll");
  }

}
