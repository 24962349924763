import { Component, AfterViewInit, Input, APP_ID, HostListener } from '@angular/core';

@Component({
    selector: 'app-share-facebook-button',
    templateUrl: './share-facebook-button.component.html',
    styleUrls: []
})

export class ShareFacebookButtonComponent implements AfterViewInit {
    @Input() url = location.href;
    @HostListener("click", ["$event"])
    onClick() {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}&display=popup&ref=plugin&src=like&kid_directed_site=0&app_id=${APP_ID}`, "_self")
    }

    constructor() {
        // initialise facebook sdk after it loads if required
        if (!window['fbAsyncInit']) {
            window['fbAsyncInit'] = function () {
                window['FB'].init({
                    appId: APP_ID,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v3.0'
                });
            };
        }

        // load facebook sdk if required
        const url = 'https://connect.facebook.net/en_US/sdk.js';
        if (!document.querySelector(`script[src='${url}']`)) {
            let script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        }
    }

    ngAfterViewInit(): void {
        // render facebook button
        window['FB'] && window['FB'].XFBML.parse();
    }
}