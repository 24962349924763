import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BannerModel } from '../models/banners/banner.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  private resource = `${environment.serverUrl}/banner`;

  constructor(private http: HttpClient) { }

  getBanners(): Observable<Array<BannerModel>> {
    return this.http.get<Array<BannerModel>>(`${this.resource}?limit=10`);
  }

  editBanners(offersIds: number[]): Observable<any> {
    return this.http.post<any>(this.resource, offersIds);
  }
  logClickOnBanner(offerIds: number): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}/log/banner_click/` + offerIds);
  }

}
