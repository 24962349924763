import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardModel } from '../models/dashboard/dashboard-model';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private resource = `${environment.serverUrl}/Dashboard`;

    constructor(private http: HttpClient) { }

    getDashboradItems(): Observable<DashboardModel[]> {
        return this.http.get<DashboardModel[]>(`${this.resource}/dashboard`);
    }
    getStatistics(): Observable<any> {
        return this.http.get(`${this.resource}/analyticsreport`);
    }
    downloadAsPdf(content, filename) {
        const options = {
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 4 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf()
            .from(content)
            .set(options)
            .save();
    }
    
    downloadAsPng(content, filename) {
        html2canvas(content).then(canvas => {
            const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            const link = document.createElement('a');
            link.href = image;
            link.download = filename;
            link.click();
        });
    }

}
