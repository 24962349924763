import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { TokenData } from '../models/token-model';
import { LoginModel } from '../models';
import { LocalStorage } from './local-storage';

@Injectable()
export class Auth {
    constructor() { }

    public static readonly AUTH_TOKEN: string = 'auth-token';
    public static readonly AUTH_PERMISSIONS: string = 'auth-permissions';
    public static readonly REDIRECT_URL: string = 'redirect-url';

    static authenticate(token: TokenData): void {
        LocalStorage.setItem(Auth.AUTH_TOKEN, token);
    }

    static setPermissions(permissions: string[]) {
        LocalStorage.setItem(Auth.AUTH_PERMISSIONS, permissions);
    }

    static isAuthenticated(): boolean {
        return LocalStorage.getItem(Auth.AUTH_TOKEN) != null;
    }

    static isAuthorized(permissions: string[][]): boolean {
        let isAuthorized: boolean = false;

        for (let perm of permissions) {
            if (Auth.authorized(perm)) {
                isAuthorized = true;
                break;
            }
        }

        return isAuthorized;
    }

    private static authorized(permissions: string[]): boolean {
        let authPermissions = LocalStorage.getItem(Auth.AUTH_PERMISSIONS);
        if (Auth.isAuthenticated() && authPermissions != null) {
            let isPermitted: boolean = true;

            for (let permission of permissions) {
                isPermitted = authPermissions.indexOf(permission) > -1;
                if (!isPermitted) {
                    break;
                }
            }

            return isPermitted;
        } else {
            return false;
        }
    }

    static clear(): void {
        LocalStorage.removeItem(Auth.AUTH_TOKEN);
        LocalStorage.removeItem(Auth.AUTH_PERMISSIONS);
    }
}

@Injectable()
export class AuthSecurity {
    private static instance: AuthSecurity;
    private resource = environment.serverUrl + '/login';

    constructor(private injector: Injector) { }

    public static init(injector: Injector) {
        this.instance = new AuthSecurity(injector);
    }

    public static getInstance(): AuthSecurity {
        return this.instance;
    }

    public refresh(): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'No-Auth': 'True',
            }),
        };

        let http: HttpClient = this.injector.get<HttpClient>(HttpClient);

        let token: TokenData = this.token();

        return http
            .post<TokenData>(
                environment.serverUrl + '/login/refresh',
                JSON.stringify(token.refresh),
                httpOptions
            )
            .pipe(
                map((token: TokenData) => {
                    if (token != null) {
                        Auth.authenticate(token);
                    } else {
                        Auth.clear();
                    }

                    return token;
                }),
                catchError((error: any) => {
                    return of(error);
                })
            );
    }

    public token(): TokenData {
        return LocalStorage.getItem(Auth.AUTH_TOKEN);
    }

    public permissions(): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let https: HttpClient = this.injector.get<HttpClient>(HttpClient);

        return https
            .get<string[]>(environment.serverUrl + '/globalpermissions', httpOptions)
            .pipe(
                map((permissions: string[]) => {
                    Auth.setPermissions(permissions);
                    return true;
                })
            );
    }

    public login(username: string, password: string): Observable<boolean> {
        let data: LoginModel = {
            username: username,
            password: password
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'No-Auth': 'True',
            }),
        };

        let http: HttpClient = this.injector.get<HttpClient>(HttpClient);

        return http.post<TokenData>(this.resource, data, httpOptions).pipe(
            map((token: TokenData) => {
                if (token != null) {
                    Auth.authenticate(token);
                    return true;
                }

                return false;
            }),
            catchError((error: any) => {
                return of(error);
            })
        );
    }
}
