import { Directive, ElementRef, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';
import { Subscription, forkJoin } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';


@Directive({
  selector: '[authorized]'
})
export class AuthorizedDirective implements OnInit, OnDestroy {

  @Input() forRoles: string[];

  subscriptions = new Subscription();

  constructor(private eleRef: ElementRef, private store: Store) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(AuthState.roles)
        .subscribe((roles) => {
          if (roles.length == 0) {
            this.eleRef.nativeElement.hidden = true;
            this.eleRef.nativeElement.setAttribute('style', 'display: none');
          }
          else {
            this.eleRef.nativeElement.hidden = true;
            this.eleRef.nativeElement.setAttribute('style', 'display: none');
            this.forRoles.forEach(role => {
              if (roles.toString() == role) {
                this.eleRef.nativeElement.hidden = false;
                this.eleRef.nativeElement.removeAttribute("style");
              }
            })
          }
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
