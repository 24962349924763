<div class="dropdown">
    <div class="dropdown-btn" [class.dropdown-btn-active]="dropDownModal" (click)="dropDownModal = true"
        (clickOutside)="dropDownModal = false">
        <span class="value" *ngIf="sort.order == 0"><b>{{sortType.label}}</b> - {{sortType.ascLabel}} </span>
        <span class="value" *ngIf="sort.order == 1"><b>{{sortType.label}}</b> - {{sortType.descLabel}} </span>
    </div>
    <div class="dropdown-list" *ngIf="dropDownModal">
        <ng-container *ngFor="let type of sortSetting.types">
            <div class="dropdown-item" (click)="changeSort(type, 'ASC')">
                <b>{{type.label}}</b> {{type.ascLabel}}
            </div>
            <div class="dropdown-item" (click)="changeSort(type, 'DESC')">
                <b>{{type.label}}</b> {{type.descLabel}}
            </div>
        </ng-container>
    </div>
</div>