import { Pipe, PipeTransform } from "@angular/core";
import { environment } from 'src/environments/environment';

@Pipe({
  name: "document",
})
export class DocumentPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      return `${environment.serverUrl}/document/${value}`;
    } else {
      return null;
    }
  }
}
