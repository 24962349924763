import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent {

  modalTerms: boolean;

  constructor() { }

  toggleModalTerms(): void {
    this.modalTerms = !this.modalTerms
  }


}
