import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngxs/store";
import { title } from "process";
import { DataState } from "src/app/state";
import { AreaModel } from "../models/other/location/location-model";


@Pipe({
  name: 'area'
})
export class AreaPipe implements PipeTransform {

  defArea: AreaModel[] = [];

  constructor(private $store: Store) {
    this.defArea = this.$store.selectSnapshot(DataState.areas);

  }
  transform(value: number): string {
    return (this.defArea.find(x => x.id == value)?.title || "")// (this.defArea.find(x=>x.id==0).title)
  }

}