
import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { TryRefresh } from 'src/app/state';

@Injectable()
export class TokenErrorInterceptor implements HttpInterceptor {
  private HTTP_UNAUTHORIZED = 401;
  private HTTP_FORBIDDEN = 403;
  constructor(public router: Router, private store: Store) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status == this.HTTP_UNAUTHORIZED || error.status == this.HTTP_FORBIDDEN) {
            this.store.dispatch(new TryRefresh());
          }
          return of(error);
        })
      );
  }
}
