import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  error(message: string, title: string = 'Error') {
    title = title || 'Error';
    setTimeout(() =>
      this.toastr.error(message, title, {
        timeOut: 5000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: true,
        positionClass: 'toast-bottom-center'
      }));
  }
}
