import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserService } from 'src/app/shared/services/browser.service';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';


@Component({
  selector: 'app-home-collections',
  templateUrl: './home-collections.component.html',
  styles: [
  ]
})
export class HomeCollectionsComponent implements OnInit {

  @Input() collections: CollectionModel[];
  collectionsPage: CollectionModel[][];

  @ViewChild('carouselcollection', { static: true }) carouselCollections: ElementRef;

  @Output() bannerReadyE: EventEmitter<any> = new EventEmitter();
  @Output() clickBannerE: EventEmitter<any> = new EventEmitter();

  // Settings
  slideSpeed: number = 1; // seconds
  intervalDuration: number = 7; // seconds
  margin: number = 20; // px
  // Carousel
  carouselPageNumber: number;
  corouselPageCurent: number = 1;
  corouselScrollLeftRight: boolean;
  timeLeft: number = this.intervalDuration;
  interval;
  intervalPause: boolean = false;
  // Other
  pageOneRady: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private browserService: BrowserService,
  ) { }

  ngOnInit(): void {
    // Servis koji prati aktivnosti browser-a odnosno da li je tab sa aplikacijom 
    // fokusiran ili ne.
    this.browserService.browserActiv().subscribe(res => this.intervalPause = !res);
    this.mapCollection();
  }

  ngAfterViewInit(): void {
    this.carouselPageNumber = Math.ceil(this.collections.length / 2);
    this.cdr.detectChanges();
    this.carouselCollections.nativeElement.children[0].style.transition = `all ${this.slideSpeed}s ease-in-out`;
    const corouselPages = this.carouselCollections.nativeElement.children;
    [...corouselPages].forEach(corouselPage => {
      corouselPage.style.marginRight = `${20}px`;
    });
    this.startTimer();
  }

  /* 
  Timer poziva kontroler na prethodno odredjeno vreme.
  Uvedena je boolean intervalPause koji ako je pozitivan
  zaustavlja timer. 

  intervalPause je pozitivan ako je aplikacija, tj tab nije fokusiran
  u browser-u. Uradjeno kako se baner ne bi pomerao ako tab nije fokusiran.
  */
  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.intervalPause) {
        return
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.controler();
        this.timeLeft = this.intervalDuration;
      }
    }, 1000)
  }

  /*
  Kontroler upravlja pomeranjem banera.
  U slujaju da baner dodje do poslednje strane
  pomeranje se nastavlja u suprotnu stranu.
  */
  controler(): void {
    if (this.corouselPageCurent == this.carouselPageNumber) {
      this.corouselScrollLeftRight = false;
    }
    else if (this.corouselPageCurent == 1) {
      this.corouselScrollLeftRight = true;
    }
    if (this.corouselScrollLeftRight) {
      this.corouselPageCurent++;
    }
    else {
      this.corouselPageCurent--;
    }

    this.carouselCollections.nativeElement.children[0].style.marginLeft = `calc(-${this.corouselPageCurent * 100 - 100}% - ${this.corouselPageCurent * this.margin - this.margin}px) `;
  }

  /*
  Na pocetku vrsi se samo ocitavanje prve strane banera, tek nakon
  toga ocitavaju se ostale slike
  */
  loadImg(currentPage: number, img: string): string {
    if (currentPage == 0 || this.pageOneRady) {
      return `${environment.serverUrl}/media/${img}.jpg`;
    }
    else return 'assets/images/default/img_def.svg'
  }

  /*
  Kada se na prvom baneru ocitaju slike, kompnenta salje
  da je spremna za prikaz
  */
  bannerIsReady(pageNumber): void {
    if (pageNumber == 0) {
      this.bannerReadyE.emit()
      this.pageOneRady = true;
    }
  }

  // Actions
  clickPage(pageNumber: number): void {
    this.timeLeft = this.intervalDuration;
    this.corouselPageCurent = pageNumber;
    this.carouselCollections.nativeElement.children[0].style.marginLeft = `calc(-${this.corouselPageCurent * 100 - 100}% - ${this.corouselPageCurent * this.margin - this.margin}px) `;
  }

  clickBanner(collectionId: number): void {
    this.clickBannerE.emit(collectionId);
  }

  // Small fix za ngFor
  numberToArray(n: number): number[] {
    return Array.from(Array(n)).map((x, i) => i);
  }

  mapCollection(): void {
    this.collectionsPage = [];
    let smallArray = [];
    this.collections.forEach((collection) => {
      if (smallArray.length == 2) {
        this.collectionsPage.push(smallArray);
        smallArray = [];
        smallArray.push(collection);
      } else {
        smallArray.push(collection);
      }
    })
    this.collectionsPage.push(smallArray);
  }

}
