<form [formGroup]="form" *ngIf="form">
    <div class="search-box">
        <div *ngIf="filterSetting.keyword" class="search-keyword">
            <div class="search-icon"></div>
            <input class="search-input" type="text" formControlName="keyword" placeholder="Search offers"
                (keydown.enter)="search()">
        </div>
        <div *ngIf="filterSetting.categories" class="search-criterion"
            [class.search-criterion-extended]="checkOptionExtended('categories')">
            <div class="criterion-header" (click)="expandOption('categories')">
                Category
            </div>
            <div class="criterion-options">
                <div class="option" *ngFor="let order of categories; let i = index" formArrayName="categories">
                    <div class="f-checkbox">
                        <input id="{{categories[i].title}}" type="checkbox" [formControlName]="i">
                        <label for="{{categories[i].title}}">{{categories[i].title}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="filterSetting.collections" class="search-criterion"
            [class.search-criterion-extended]="checkOptionExtended('collections')">
            <div class="criterion-header" (click)="expandOption('collections')">
                Collection
            </div>
            <div class="criterion-options">
                <div class="option" *ngFor="let order of collections; let i = index" formArrayName="collections">
                    <div class="f-checkbox">
                        <input id="{{collections[i].title}}" type="checkbox" [formControlName]="i">
                        <label for="{{collections[i].title}}">{{collections[i].title}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="filterSetting.tags" class="search-criterion"
            [class.search-criterion-extended]="checkOptionExtended('tags')">
            <div class="criterion-header" (click)="expandOption('tags')">
                Tags
            </div>
            <div class="criterion-options">
                <div class="option" *ngFor="let order of tags; let i = index" formArrayName="tags">
                    <div class="f-checkbox">
                        <input id="{{tags[i].title}}" type="checkbox" [formControlName]="i">
                        <label for="{{tags[i].title}}">{{tags[i].title}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="filterSetting.status" class="search-criterion"
            [class.search-criterion-extended]="checkOptionExtended('status')">
            <div class="criterion-header" (click)="expandOption('status')">
                Status
            </div>
            <div class="criterion-options">
                <div class="option" *ngFor="let order of status; let i = index" formArrayName="status">
                    <div class="f-checkbox">
                        <input id="{{status[i]}}" type="checkbox" [formControlName]="i">
                        <label for="{{status[i]}}">{{status[i]}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="filterSetting.locations" class="search-criterion"
            [class.search-criterion-extended]="checkOptionExtended('locations')">
            <div class="criterion-header" (click)="expandOption('locations')">
                Locations
            </div>
            <div class="criterion-options">
                <div class="option" *ngFor="let order of locations; let i = index" formArrayName="locations">
                    <div class="f-checkbox">
                        <input id="{{locations[i]}}" type="checkbox" [formControlName]="i">
                        <label for="{{locations[i]}}">{{locations[i]}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>