import { Injectable, HostListener, ElementRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  private browserActivS: BehaviorSubject<boolean>;

  constructor() {
    this.browserActivS = new BehaviorSubject<boolean>(true);
    this.subBrowser();
  }

  subBrowser() {
    document.addEventListener('visibilitychange', () => {
      this.browserActivS.next(!document.hidden);
    });
  }

  browserActiv(): Observable<boolean> {
    return this.browserActivS.asObservable();
  }
}
