import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { DataStateModel, ResetData, UpdateAreas, UpdateCategories, UpdateCollections, UpdateLocations, UpdateTags, UpdateMemberships } from "./data-state-model";
import { AreaModel, LocationModel } from "../shared/models/other/location/location-model";
import { CategoryModel } from "../shared/models/categories";
import { CollectionModel } from "../shared/models/collections/collection-model";
import { TagModel } from "../shared/models/tags";
import * as _ from "lodash";
import { MembershipModel } from "../shared/models/memberships/membershipModel";


@State<DataStateModel>({
  name: "data",
  defaults: {
    locations: [],
    areas: [],
    categories: [],
    collections: [],
    tags: [],
    memberships: []
  },
})
@Injectable()
export class DataState {
  constructor() { }

  // SELECTORS
  @Selector()
  static locations(state: DataStateModel): LocationModel[] {
    return state.locations;
  }

  @Selector()
  static areas(state: DataStateModel): AreaModel[] {
    return state.areas;
  }

  @Selector()
  static categories(state: DataStateModel): CategoryModel[] {
    return state.categories;
  }

  @Selector()
  static collections(state: DataStateModel): CollectionModel[] {
    return state.collections;
  }

  @Selector()
  static tags(state: DataStateModel): TagModel[] {
    return state.tags;
  }

  @Selector()
  static memberships(state: DataStateModel): MembershipModel[] {
    return state.memberships;
  }

  // ACTIONS
  @Action(UpdateLocations)
  updateLocations(ctx: StateContext<DataStateModel>, action: UpdateLocations) {
    ctx.patchState({
      locations: action.locations
    });
  }

  @Action(UpdateAreas)
  updateAreas(ctx: StateContext<DataStateModel>, action: UpdateAreas) {
    ctx.patchState({
      areas: action.areas
    });
  }

  @Action(UpdateCategories)
  updateCategories(ctx: StateContext<DataStateModel>, action: UpdateCategories) {
    ctx.patchState({
      categories: action.categories
    });
  }

  @Action(UpdateCollections)
  updateCollections(ctx: StateContext<DataStateModel>, action: UpdateCollections) {
    ctx.patchState({
      collections: action.collections
    });
  }

  @Action(UpdateTags)
  updateTags(ctx: StateContext<DataStateModel>, action: UpdateTags) {
    ctx.patchState({
      tags: action.tags
    });
  }
  @Action(UpdateMemberships)
  updateMemberships(ctx: StateContext<DataStateModel>, action: UpdateMemberships) {
    ctx.patchState({
      memberships: action.memberships
    });
  }

  // Reset
  @Action(ResetData)
  resetData(ctx: StateContext<DataStateModel>, action: ResetData) {
    ctx.setState({
      locations: [],
      areas: []
    });
  }


}
