import { UpdateAreas } from './../../../state/data-state-model';
import { filter } from 'rxjs/operators';
import { query } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { PaginationModel } from '../../models/pagination/pagination-model';
import { OfferPostModel } from '../../models/offers/offer-post-model';
import { AuthState, PageHistoryState } from 'src/app/state';
import { Store } from '@ngxs/store';
import { merge, of } from 'rxjs';
import { StateChange } from 'ng-lazyload-image';
import { NotificationsService } from '../../services/notifications.service';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

@Component({
  selector: 'offer-list',
  templateUrl: './offer-list.component.html',
  styles: []
})
export class OfferListComponent implements OnInit {

  @Input() offersPage: PaginationModel<OfferPostModel>;
  @Input() collapse: boolean;
  @Input() checkerActive: boolean = false;
  @Input() checkedOffersIds: number[] = [];

  @Output() onItemClick = new EventEmitter();

  areasId: number[];
  offerIdsInProgress: number[] = [];

  statusVisible: boolean;
  role: string;
  imageLoadOffset$: any;
  flag: boolean = false;

  constructor(
    private store$: Store,
    private _notificationService: NotificationsService
  ) {

    this.store$.select(PageHistoryState).subscribe((res) => {
      this.areasId = res.query.filter.areas.map(area => area.id)

    })
    this.imageLoadOffset$ = merge(of(300), of(100000));

  }


  ngOnChanges(changes: SimpleChanges): void {
    this.store$.select(PageHistoryState).subscribe((res) => {
      this.areasId = res.query.filter.areas.map(area => area.id)

    })
    if (this.offersPage != undefined && this.areasId.length > 0 && this.flag)
      this.offersPage.list
        = this.offersPage.list.map(offer => {
          return {
            ...offer, locations: offer.locations.filter(location => {
              let flag = false
              this.areasId.forEach(value => {
                if (value == location.defaultAreaId) {
                  flag = true
                }
              })
              return flag
            })
          };

        })
    this.flag = true;
  }


  ngOnInit() {
    this.areasId = [];

    this.setStatusVisibility();
  }

  onItem(id: number) {
    if (this.offerIdsInProgress.includes(id)) {
      this._notificationService.info('Info', 'Offer in progres please for few moments');
    } else {
      this.onItemClick.emit(id);
    }
  }

  setStatusVisibility() {
    this.store$.select(AuthState.roles)
      .subscribe((roles) => {
        this.role = roles[0];
        if (this.role === 'Reviewer' || this.role === 'AdnocCoordinator' || this.role === 'Admin') {
          this.statusVisible = true
        } else {
          this.statusVisible = false;
        }
      })
  };


  checked(id: number): boolean {
    if (this.checkedOffersIds.includes(id)) return true
    else return false;
  }
}
