<div class="notif-container">
    <div *ngFor="let notif of notifications" [class.notif-sucess]="notif.type == 0"
        [class.notif-warning]="notif.type == 1" [class.notif-error]="notif.type == 2"
        [class.notif-info]="notif.type == 3" [class.notif-refresh]="notif.type == 4" (click)="close(notif)">
        <div class="notif-icon"></div>
        <div class="notif-content">
            <div class="title"> {{notif.title}}</div>
            <div class="text"> {{notif.message}}</div>
            <div class="refresh" *ngIf="notif.type == 4" (click)="refresh()">Refresh Page</div>
        </div>

    </div>
</div>