import { SortModel } from './sortModel';
import { FilterModel } from './filterModel';

export class QueryModel {
  sort: SortModel;
  filter: FilterModel;
  page: number;

  constructor() {
    this.sort = new SortModel();
    this.filter = new FilterModel();
    this.page = 1;
  }
}

