import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-redirection',
  templateUrl: './membership-redirection.component.html',
  styles: [
  ]
})
export class MembershipRedirectionComponent implements OnInit {

  pom = "redirecting";
  interval;
  intervalDuration: number = 7; // seconds

  timeLeft: number = this.intervalDuration;
  constructor() { }

  ngOnInit(): void {
    this.startTimer();
  }
  startTimer(): void {
    this.interval = setInterval(() => {

      this.pom = "redirecting complete";
    }, 5000)
  }

}
