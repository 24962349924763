import { AreaModel, LocationModel } from '../shared/models/other/location/location-model';
import { CategoryModel } from '../shared/models';
import { CollectionModel } from '../shared/models/collections/collection-model';
import { TagModel } from '../shared/models/tags';
import { MembershipModel } from '../shared/models/memberships/membershipModel';

export interface DataStateModel {
  locations?: LocationModel[] | null,
  areas?: AreaModel[] | null,
  categories?: CategoryModel[] | null,
  collections?: CollectionModel[] | null,
  tags?: TagModel[] | null
  memberships?: MembershipModel[] | null
}

export class UpdateLocations {
  static readonly type = '[Data] UpdateLocations';
  constructor(public locations) { }
}
export class UpdateMemberships {
  static readonly type = '[Data] UpdateMemberships';
  constructor(public memberships) { }
}

export class UpdateAreas {
  static readonly type = '[Data] UpdateAreas';
  constructor(public areas) { }
}

export class UpdateCategories {
  static readonly type = '[Data] UpdateCategories';
  constructor(public categories) { }
}

export class UpdateCollections {
  static readonly type = '[Data] UpdateCollections';
  constructor(public collections) { }
}

export class UpdateTags {
  static readonly type = '[Data] UpdateTags';
  constructor(public tags) { }
}

export class ResetData {
  static readonly type = '[Data] ResetData';
  constructor() { }
}

export class LogoutTest {
  static readonly type = '[Data] LogoutTest';
  constructor() { }
}

