import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";
import { TokenData } from "../models/token-model";
import { Auth } from "../security/auth";
import { environment } from "src/environments/environment";
import { IdentityProviderInfo } from "../models/identity-provider-info";
import { IdentityProviersStorageService } from "./identity-providers-storage.service";

@Injectable({ providedIn: "root" })
export class IdentityProviersService {
  // this is a hack due to AuthInterceptor
  private noAuthHeaders = { headers: { "No-Auth": "True" } };

  constructor(
    private httpClient: HttpClient,
    private identityProviersStorageService: IdentityProviersStorageService
  ) { }

  async getAuthenticationResults(hash: string) {
    
    const authenticationResultPromises = this.identityProviersStorageService
      .getStoredIdentityProviders()
      .map(async (idp) => {        
        const application = this.getApplication(idp);
        const redirectionResult = await application.handleRedirectPromise(hash).then().catch(() => {
          return null;
        });

        if (redirectionResult === null) return null;
        const tokenData = await this.authenticate(idp, redirectionResult);

        return { idp, tokenData };
      });

    const result = await Promise.all(authenticationResultPromises);

    return result.filter((data) => data !== null);
  }

  clearIdentityStorage() {
    this.identityProviersStorageService.clear();
  }

  getIdentityProviders() {
    const url = `${environment.serverUrl}/identity-providers`;
    return this.httpClient.get<IdentityProviderInfo[]>(url, this.noAuthHeaders);
  }

  getAllAccounts(identityProviderInfo: IdentityProviderInfo) {
    return this.getApplication(identityProviderInfo).getAllAccounts();
  }

  getApplication(identityProviderInfo: IdentityProviderInfo) {

    const redirectUri = this.getRedirectUrl();
    const application = new PublicClientApplication({
      auth: { ...identityProviderInfo, redirectUri },
      cache: {
        cacheLocation: "localStorage",
      },
    });

    return application;
  }

  private getRedirectUrl() {
    return `${window.location.origin}/login`;
  }

  async silentLogin(identityProviderInfo: IdentityProviderInfo) {
    const [account] = this.getAllAccounts(identityProviderInfo);
    return this.getApplication(identityProviderInfo).acquireTokenSilent({
      account,
      scopes: identityProviderInfo.scopes,
    });
  }

  async identityProviderLogin(idp: IdentityProviderInfo) {
    this.identityProviersStorageService.saveIdentityProviders([idp]);
    var application = this.getApplication(idp);

    await application.loginRedirect(idp);
  }

  private async authenticate(
    identityProviderInfo: IdentityProviderInfo,
    { accessToken }: AuthenticationResult
  ) {
    const tokenData = await this.httpClient
      .post<TokenData>(
       // identityProviderInfo.loginApi,
       "https://mazaya-api.gewaninfotech.com/api/login-b2c-as-customer",
        {},
        {
          headers: {
            ...this.noAuthHeaders.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .toPromise();
    Auth.authenticate(tokenData);

    return tokenData;
  }
}
