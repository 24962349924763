import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DeviceCapabilitiesService } from '../core/services';
import { SettingsStateModel, CheckIsImageDeferLoadAllowed, SaveIdentityProvidersConfiguration } from './app-settings-state-model';

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    identityProvidersInfo: [],
    allowImageDeferLoad: false
  }
})
@Injectable()
export class SettingsState {
  @Selector()
  static allowImageDeferLoad(state: SettingsStateModel): boolean {
    return state.allowImageDeferLoad;
  }

  constructor(private deviceCapabilitiesService: DeviceCapabilitiesService) { }

  @Action(CheckIsImageDeferLoadAllowed)
  login(ctx: StateContext<SettingsStateModel>, action: CheckIsImageDeferLoadAllowed) {
    const result = this.deviceCapabilitiesService.allowImageDeferLoad();
    ctx.patchState({
      allowImageDeferLoad: result
    });
  }

  @Action(SaveIdentityProvidersConfiguration)
  saveIdentityProvidersConfiguration(ctx: StateContext<SettingsStateModel>, action: SaveIdentityProvidersConfiguration) {
    ctx.patchState({
      identityProvidersInfo: action.identityProvidersInfo
    });
  }
}
