import { FilterSettingModel } from 'src/app/shared/models/query/filterSettingModel';
import { FilterModel } from 'src/app/shared/models/query/filterModel';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CategoryModel } from '../../models/categories/category-model';
import { CollectionModel } from '../../models/collections/collection-model';
import { TagModel } from '../../models/tags';
import { OfferService } from 'src/app/features/offers/services';
import { CategoriesService } from 'src/app/features/categories/services';
import { CollectionsService } from 'src/app/features/collections/services';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'offer-filters',
  templateUrl: './offer-filters.component.html',
  styles: []
})
export class OfferFiltersComponent implements OnInit {
  @Input() filter: FilterModel;
  @Input() filterSetting: FilterSettingModel;

  @Output() onFilterChange = new EventEmitter();

  form: UntypedFormGroup;
  categories: CategoryModel[];
  collections: CollectionModel[];
  tags: TagModel[];
  locations: string[] = ['Abu Dhabi', 'Ajman', 'Dubai', 'Fujairah', 'Ras Al Khaimah', 'Sharjah', 'Umm Al Quwai', 'International'];
  status: string[] = ['Draft', 'Review', 'Approved', 'Rejected', 'Pending Approval', 'Expired', 'Blocked', 'Cancelled'];
  rating: number[] = [1, 2, 3, 4, 5];

  keyword: string = '';

  extendedOptions: string[] = [];

  constructor(
    private _fb: UntypedFormBuilder,
    private _offerService: OfferService,
    private _categoriesService: CategoriesService,
    private _collectionsService: CollectionsService
  ) { }

  ngOnInit() {
    this.initFilterData();
  }

  initFilterData() {
    forkJoin([
      this._categoriesService.getAllCategories(),
      this._collectionsService.getAllCollections(),
      this._offerService.getAllTags(),
    ]).subscribe(([categories, collections, tags]) => {
      this.categories = categories;
      this.collections = collections;
      this.tags = tags;
      this.initForm()
    });
  }

  initForm() {
    this.form = this._fb.group({
      categories: new UntypedFormArray(this.fieldFromArray('categories')),
      collections: new UntypedFormArray(this.fieldFromArray('collections')),
      tags: new UntypedFormArray(this.fieldFromArray('tags')),
      status: new UntypedFormArray(this.fieldFromArray('status')),
      locations: new UntypedFormArray(this.fieldFromArray('locations')),
      rating: new UntypedFormControl(this.fieldFromArray('rating')),
      keyword: new UntypedFormControl(this.filter.keyword)
    });
    this.onFormValueChanges();
  }

  fieldFromArray(field) {
    let ret: UntypedFormControl[] = [];
    this[field].forEach(arr => ret.push(new UntypedFormControl(this.filter[field].includes(arr.id || arr)))
    )
    return ret;
  }

  onFormValueChanges(): void {
    this.form.valueChanges.subscribe(val => {

      // Fix - search only on Enter 
      if (val.keyword != this.keyword) {
        this.keyword = val.keyword;
        this.filter.keyword = val.keyword;
        return;
      }

      this.filter.categories = this.mapFormId('categories', val.categories)
      this.filter.collections = this.mapFormId('collections', val.collections)
      this.filter.locations = this.mapForm('locations', val.locations)
      this.filter.tags = this.mapFormId('tags', val.tags)
      this.filter.ratings = this.mapForm('categories', val.rating)
      this.filter.status = this.mapForm('status', val.status)
      this.onFilterChange.emit(this.filter);
    })
  }

  search() {
    this.onFilterChange.emit(this.filter);
  }

  mapFormId(field: string, results: boolean[]) {
    let ret = [];
    results.map((v, i) => v ? ret.push(this[field][i].id) : null)
    return ret
  }
  mapForm(field: string, results: boolean[]) {
    let ret = [];
    results.map((v, i) => v ? ret.push(this[field][i]) : null)
    return ret
  }

  expandOption(option: string): void {
    const index = this.extendedOptions.indexOf(option);
    if (index > -1) this.extendedOptions.splice(index, 1);
    else this.extendedOptions.push(option);
  }


  checkOptionExtended(option: string): boolean {
    return this.extendedOptions.includes(option);
  }
}

