<div class="modal-container" *ngIf="text" modal [modalActive]="modalTerms && text">
    <div class="modal modal-width-m">
        <div class="modal-header">Terms & Conditions</div>
        <div class="modal-tabs">
            <div class="tab" [class.tab-active]="modalTermsTab == 'en'" (click)="toggleModalTermsTab('en')">ENGLISH
            </div>
            <div class="tab" [class.tab-active]="modalTermsTab == 'ar'" (click)="toggleModalTermsTab('ar')">ARABIC</div>
        </div>
        <div class="modal-content" [class.modal-content-right]="modalTermsTab == 'ar'">
            <div class="modal-article" [innerHTML]="text"></div>
        </div>
        <div class="modal-footer">
            <button class="btn-grey" (click)="closeModal()">Close</button>
        </div>
    </div>
</div>