import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { FilterSettingModel } from 'src/app/shared/models/query/filterSettingModel';
import { FilterModel } from 'src/app/shared/models/query/filterModel';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CategoryModel } from 'src/app/shared/models/categories/category-model';
import { TagModel } from 'src/app/shared/models/tags/tag-model';
import { Subscription } from 'rxjs';
import { AreaModel, LocationModel } from '../../models/other/location/location-model';
import { Options } from '@angular-slider/ngx-slider';
import { Store } from '@ngxs/store';
import { DataState } from 'src/app/state';
import { MembershipModel } from '../../models/memberships/membershipModel';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styles: []
})
export class FilterComponent implements OnInit, OnDestroy {

  @Input() filter: FilterModel;
  @Input() filterSetting: FilterSettingModel;
  @Input() oneFilter: boolean = false;
  @Output() onFilterChange = new EventEmitter();
  @Output() closeFilter = new EventEmitter();

  form: UntypedFormGroup;
  categories: CategoryModel[];
  collections: CollectionModel[];
  tags: TagModel[];
  locations: LocationModel[] = [];
  areas: AreaModel[] = [];
  status: string[] = ['Activated','Draft', 'Review', 'Approved', 'Rejected', 'Pending Approval', 'Expired', 'Blocked', 'Cancelled'];
  type: string[] = [];
  ratings: string[];
  memberships: MembershipModel[] = [];

  extendedOptions: string[] = [];

  optionsSliderPrice: Options = {
    floor: 1,
    ceil: 100000,
    animate: false,
    logScale: true,
    translate: (value: number): string => {
      return value + ' AED';
    }
  };

  filterActiveGroup: string[] = [];

  $filter: Subscription = new Subscription();

  constructor(
    private _fb: UntypedFormBuilder,
    private $store: Store,
  ) { }

  ngOnInit(): void {
    this.initStaticFilter();
    this.initDynamicFilter();
    this.updateFilterCounter();
    this.updateFilterActive();
  }

  ngOnDestroy(): void {
    this.$filter.unsubscribe();
  }

  updateFilterActive() {
    this.extendedOptions = this.filter.filterOpenGroup;
  }

  updateFilterCounter() {
    let filterActiveGroup = [];
    if (this.filter.categories.length > 0) {
      filterActiveGroup.push('categories');
    }
    if (this.filter.collections.length > 0) {
      filterActiveGroup.push('collections');
    }
    if (this.filter.memberships.length > 0) {
      filterActiveGroup.push('memberships');
    }
    if (this.filter.tags.length > 0) {
      filterActiveGroup.push('tags');
    }
    if (this.filter.status.length > 0) {
      filterActiveGroup.push('status');
    }
    if (this.filter.type.length > 0) {
      filterActiveGroup.push('type');
    }
    if (this.filter.locations.length > 0) {
      filterActiveGroup.push('locations');
    }
    if (this.filter.areas.length > 0) {
      filterActiveGroup.push('areas');
    }
    if (this.filter.ratings.length > 0) {
      filterActiveGroup.push('ratings');
    }
    if (this.filter.priceFrom > 1 || this.filter.priceTo < 100000) {
      filterActiveGroup.push('price');
    }
    if (this.filter.discountFrom > 0 || this.filter.discountTo < 100) {
      filterActiveGroup.push('discount');
    }
    if (this.filter.keyword != "") {
      filterActiveGroup.push('keyword');
    }

    this.filterActiveGroup = filterActiveGroup;
  }


  initStaticFilter(): void {
    if (this.filterSetting.status) {
      this.status = this.filterSetting.status;
    }
    if (this.filterSetting.type) {
      this.type = this.filterSetting.type;
    }
    this.ratings = ['1', '2', '3', '4', '5']
  }

  initDynamicFilter() {
    this.categories = this.$store.selectSnapshot(DataState.categories);
    this.collections = this.$store.selectSnapshot(DataState.collections);
    this.tags = this.$store.selectSnapshot(DataState.tags);
    this.locations = this.$store.selectSnapshot(DataState.locations);
    this.areas = this.$store.selectSnapshot(DataState.areas);
    this.memberships = this.$store.selectSnapshot(DataState.memberships);
    this.initForm();
  }

  initForm() {
    this.form = this._fb.group({
      categories: new UntypedFormArray(this.filterSetting.categories ? this.fieldFromArray('categories') : []),
      collections: new UntypedFormArray(this.filterSetting.collections ? this.fieldFromArray('collections') : []),
      memberships: new UntypedFormArray(this.filterSetting.memberships ? this.fieldFromArray('memberships') : []),
      tags: new UntypedFormArray(this.filterSetting.tags ? this.fieldFromArray('tags') : []),
      status: new UntypedFormArray(this.filterSetting.status ? this.fieldFromArray('status') : []),
      type: new UntypedFormArray(this.filterSetting.type ? this.fieldFromArray('type') : []),
      locations: new UntypedFormArray(this.filterSetting.locations ? this.fieldFromArray('locations') : []),
      areas: new UntypedFormArray(this.filterSetting.areas ? this.fieldFromArray('areas') : []),
      ratings: new UntypedFormArray(this.filterSetting.rating ? this.fieldFromArray('ratings') : []),
      discountM: new UntypedFormControl([this.filter.discountFrom, this.filter.discountTo]),
      discountFrom: new UntypedFormControl(this.filter.discountFrom),
      discountTo: new UntypedFormControl(this.filter.discountTo),
      priceM: new UntypedFormControl([this.filter.priceFrom, this.filter.priceTo]),
      priceFrom: new UntypedFormControl(this.filter.priceFrom),
      priceTo: new UntypedFormControl(this.filter.priceTo),
      keyword: new UntypedFormControl(this.filter.keyword),
      keywordM: new UntypedFormControl(this.filter.keyword),
    });
    this.onFormValueChanges();
  }

  // Prati promene vrednosti u formi
  onFormValueChanges(): void {
    this.$filter
      .add(
        this.form.controls['categories'].valueChanges.subscribe(res => {
          this.filter.categories = this.mapFormId('categories', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['collections'].valueChanges.subscribe(res => {
          this.filter.collections = this.mapFormId('collections', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['locations'].valueChanges.subscribe(res => {
          this.filter.locations = this.mapForm('locations', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['areas'].valueChanges.subscribe(res => {
          this.filter.areas = this.mapForm('areas', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['ratings'].valueChanges.subscribe(res => {
          this.filter.ratings = this.mapForm('ratings', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['tags'].valueChanges.subscribe(res => {
          this.filter.tags = this.mapFormId('tags', res)
          this.changeFilter();
        })
      ).add(
        this.form.controls['memberships'].valueChanges.subscribe(res => {
          this.filter.memberships = this.mapForm('memberships', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['status'].valueChanges.subscribe(res => {
          this.filter.status = this.mapForm('status', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['type'].valueChanges.subscribe(res => {
          this.filter.type = this.mapForm('type', res)
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['discountFrom'].valueChanges.subscribe(res => {
          this.filter.discountFrom = res;
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['discountTo'].valueChanges.subscribe(res => {
          this.filter.discountTo = res;
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['priceFrom'].valueChanges.subscribe(res => {
          this.filter.priceFrom = res;
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['priceTo'].valueChanges.subscribe(res => {
          this.filter.priceTo = res;
          this.changeFilter();
        })
      )
      .add(
        this.form.controls['keyword'].valueChanges.subscribe(res => {
          this.filter.keyword = res;
          this.changeFilter();
        })
      )
  }

  /*
  U slucaju da zelimo promenu izvrsimo tek nakon procesa popunjavanja inputa sto je slucaj
  kod tekstualnih inputa ili range inputa, pravimo u formi mock kontrolu (primer: keywordM)
  ciju vrednost cemo proslediti pravoj kontroli (primer keyword) pozivanje funkcije
  (primer changeKeyword).
  */

  changeKeyword() {
    this.form.controls['keyword'].patchValue(this.form.controls['keywordM'].value, { emitEvent: true });
  }

  changeDiscount() {
    if (this.form.controls['discountM'].value[0] != this.form.controls['discountFrom'].value) {
      this.form.controls['discountFrom'].patchValue(this.form.controls['discountM'].value[0], { emitEvent: true });
    } else {
      this.form.controls['discountTo'].patchValue(this.form.controls['discountM'].value[1], { emitEvent: true });
    }
  }

  changePrice() {
    if (this.form.controls['priceM'].value[0] != this.form.controls['priceFrom'].value) {
      this.form.controls['priceFrom'].patchValue(this.form.controls['priceM'].value[0], { emitEvent: true });
    } else {
      this.form.controls['priceTo'].patchValue(this.form.controls['priceM'].value[1], { emitEvent: true });
    }
  }

  // Potpuni reset filtera
  resetFilter() {
    this.filter = new FilterModel();
    this.initForm();
    this.extendedOptions = [];
    this.changeFilter();
  }

  // Zatavara sve otvorene filtere
  collapseFilter() {
    this.extendedOptions = [];
  }

  changeFilter() {
    this.updateFilterCounter();
    this.filter.filterActiveGroup = this.filterActiveGroup;
    this.filter.filterOpenGroup = this.extendedOptions;
    this.onFilterChange.emit(this.filter);
  }

  // Mapiranje 
  fieldFromArray(field) {
    let ret: UntypedFormControl[] = [];
    this[field].forEach(arr => ret.push(new UntypedFormControl(this.filter[field].includes(arr.id || arr)))
    )
    return ret;
  }

  mapFormId(field: string, results: boolean[]) {
    let ret = [];
    results.map((v, i) => v ? ret.push(this[field][i].id) : null)
    return ret
  }

  mapForm(field: string, results: boolean[]) {
    let ret = [];
    results.map((v, i) => v ? ret.push(this[field][i]) : null)
    return ret
  }

  // Opcija prosirenja filtera
  expandOption(option: string): void {
    let extendedOptions = [...this.extendedOptions];
    const index = extendedOptions.findIndex(x => x === option);
    if (index > -1) extendedOptions.splice(index, 1);
    else extendedOptions.push(option);

    this.extendedOptions = [...extendedOptions];
  }

  checkOptionExtended(option: string): boolean {
    return this.extendedOptions.includes(option);
  }

  closeFilterF() {
    this.closeFilter.emit();
  }

}
