import { LoginService } from "src/app/core/services";
import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

const allowedExtensions = [".pdf", ".jpg", ".jpeg", ".png"];
@Component({
  selector: "app-company-register-form2",
  templateUrl: "./company-register-form2.component.html",
})
export class CompanyRegisterForm2Component implements OnInit {
  @Input() form: UntypedFormGroup;

  fileName: string = "Upload Trace Licence Here";
  fileValidationMessage = "";

  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() backEmitter: EventEmitter<number> = new EventEmitter<number>();

  todaysDate: any = new Date();
  fileValid: boolean;

  constructor(private loginService: LoginService) { }

  ngOnInit() { }

  nextButton() {
    if (this.form.valid && this.fileValid) {
      this.emitter.emit();
    }
    this.form.markAllAsTouched();
  }

  previousButton() {
    this.backEmitter.emit();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file.name
        .substr(file.name.lastIndexOf("."))
        .toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        this.fileValidationMessage = "Unsupported file extension";
        this.fileName = "Upload Trace Licence Here";
        this.fileValid = false;
        return;
      } else {
        this.fileValidationMessage = "";
        this.fileValid = true;
      }

      let fileDocument = {
        id: "",
        type: file.type,
        name: file.name,
      };
      this.fileName = event.target.files[0].name;

      this.loginService.saveTradeLicence(file).subscribe(
        (res) => (
          (fileDocument = {
            id: res.id,
            type: file.type,
            name: file.name,
          }),
          this.form.patchValue({
            tradeLicence: fileDocument,
          })
        )
      );
    }
  }
}
