import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { PaginationModel } from 'src/app/shared/models/pagination/pagination-model';
import { QueryModel } from 'src/app/shared/models/query/queryModel';

@Injectable({ providedIn: 'root' })
export class CollectionsService {

  private collections = `${environment.serverUrl}/collection`;

  constructor(private http: HttpClient) {

  }

  getCollectionsPage(queryModel: QueryModel): Observable<PaginationModel<CollectionModel>> {
    const url = `${this.collections}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getAllCollections(): Observable<CollectionModel[]> {
    const url = `${this.collections}`;
    return this.http.get<CollectionModel[]>(url);
  }
}
