<div class="e-card-container">
    <a (click)="goback()" class="back-link">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M20 11H7.414l5.293-5.293a1 1 0 0 0-1.414-1.414l-7 7a1 1 0 0 0-.002 1.414l7 7a1 1 0 0 0 1.414-1.414L7.414 13H20a1 1 0 0 0 0-2z"/>
        </svg>
        Go Back
      </a>
    <div class="title">Card List</div>
    <div class="card-container">
        <div class="card1" *ngFor="let card of membershipDetails; let i = index">
            <div class="card-new" *ngIf="card.profile_image!=null" [ngClass]="{ 'flipped': isFlippedArray[i] }" (click)="toggleCard(i)">
                <div class="front-new">

                    <img *ngIf="card.sort_order==1" src="assets/images/mazayaPlus/Mazaya-Cards/Gray-front-plain.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==2" src="assets/images/mazayaPlus/Mazaya-Cards/Yellow-front-plain.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==3" src="assets/images/mazayaPlus/Mazaya-Cards/Blue-front-plain.svg"
                        alt="front">

                    <div class="text-bottom-right">
                        <div class="category-name">
                            <div>{{card.catname_arabic}}</div>
                            <div>{{card.catname_english}}</div>
                        </div>

                        <div>31/12/2024</div>
                        <div>{{card.card_number}}</div>
                        <div>{{card.firstname}} {{card.lastname}}</div>
                    </div>
                    <div class="avatar-bottom-left">

                        <img class="upload-image" *ngIf="card.profile_image" [src]="card.profile_image" alt="">
                        <div class="image-empty" *ngIf="!card.profile_image"></div>
                    </div>
                </div>

                <div class="back-new">
                    <img *ngIf="card.sort_order==1" src="assets/images/mazayaPlus/Mazaya-Cards/Gray-back.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==2" src="assets/images/mazayaPlus/Mazaya-Cards/Yellow-back.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==3" src="assets/images/mazayaPlus/Mazaya-Cards/Blue-back.svg"
                        alt="front">
                </div>
            </div>
            <div class="card-new" *ngIf="card.profile_image==null">
                <!-- <div class="front-new" >
                    <img *ngIf="card.sort_order==1" src="assets/images/mazayaPlus/Mazaya-Cards/grey-blur.svg" alt="front">
                    <img *ngIf="card.sort_order==2" src="assets/images/mazayaPlus/Mazaya-Cards/yellow-blur.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==3" src="assets/images/mazayaPlus/Mazaya-Cards/blue-blur.svg" alt="front">
                    <div class="text-center">
                        Please invite the family members and upload the photo in order to activate the card
                    </div>
                </div> -->
                <div class="image-container">
                    <img *ngIf="card.sort_order==1" src="assets/images/mazayaPlus/Mazaya-Cards/grey-blur.svg" alt="front">
                    <img *ngIf="card.sort_order==2" src="assets/images/mazayaPlus/Mazaya-Cards/yellow-blur.svg"
                        alt="front">
                    <img *ngIf="card.sort_order==3" src="assets/images/mazayaPlus/Mazaya-Cards/blue-blur.svg" alt="front">
                    <div class="overlay" ></div>
                </div>
                <div class="text-center">
                    Please invite the family members and upload the photo in order to activate the card
                </div>
            </div>
        </div>


    </div>



</div>