import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-company-register-form3",
  templateUrl: "./company-register-form3.component.html",
  styleUrls: [],
})
export class CompanyRegisterForm3Component implements OnInit {
  @Input() form: UntypedFormGroup;

  @Output() backEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() registerEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  previousButton() {
    this.backEmitter.emit();
  }

  onRegister() {
    if (this.form.valid) {
      this.registerEmitter.emit();
    }
  }
}
