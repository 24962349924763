import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { NotificationType, Notification } from '../models/notifications/notification';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserNotification } from '../models/notifications/user-notification';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  private notificationService = `${environment.serverUrl}/usernotifications/`;
  private _subject = new Subject<Notification>();
  private _idx = 0;

  constructor(private http: HttpClient) { }

  // getNotifications(): Observable<Notification> {
  //   return this._subject.asObservable();
  // }

  getNotifications(): Observable<Array<UserNotification>> {
    return this.http.get<Array<UserNotification>>(this.notificationService + 'all');
  }

  acknowledgeNotification(notificationID: number): Observable<UserNotification> {
    return this.http.post<UserNotification>(this.notificationService + `${notificationID}/acknowledge`, notificationID);
  }

  getNotificationsCount(): Observable<number> {
    return this.http.get<number>(this.notificationService + 'count');
  }

  acknowledgeAllNotification(): Observable<UserNotification> {
    return this.http.post<UserNotification>(this.notificationService + 'acknowledge', null);
  }
}
