import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() {
    this.loader = new BehaviorSubject<boolean>(false);
  }

  private loader: BehaviorSubject<boolean>;
  private heap: number = 0;

  private noCheckRequest: string[] = [
    'usernotifications/count'
  ];

  activeLoader(url: string) {
    if (url.includes('usernotifications/count')) {
      return;
    }
    if (url.includes('users/userexists')) {
      return;
    }
    this.heap++;
    setTimeout(() => {
      if (this.heap >= 1) {
        this.loader.next(true);
      }
    }, 1000);
  }

  deactiveLoader(url: string) {
    if (url.includes('usernotifications/count')) {
      return;
    }
    if (url.includes('users/userexists')) {
      return;
    }
    this.heap--;
    if (this.heap === 0) {
      setTimeout(() => {
        if (this.heap === 0) {
          this.loader.next(false);
        }
      }, 500);
    }
  }

  loaderStatus(): Observable<boolean> {
    return this.loader.asObservable();
  }

  checkIfUrlIsInNoCheckRequest(url: string): boolean {
    this.noCheckRequest.forEach(element => {
      if (url.includes(element)) {
        return true;
      }
    });
    return false;
  }
}
