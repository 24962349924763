import { DashboardService } from './../../../../../shared/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { DashboardModel } from 'src/app/shared/models/dashboard/dashboard-model';

@Component({
  selector: 'app-home-supplier-admin',
  templateUrl: './home-supplier-admin.component.html',
  styles: []
})
export class HomeSupplierAdminComponent implements OnInit {

  public dashboardItems: DashboardModel[];

  constructor(
    private dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.getAllItems();
  }

  getAllItems() {
    this.dashboardService.getDashboradItems().subscribe((res) => {
      this.dashboardItems = res;
    });
  }

  getCountFromTitle(title: string): number {
    if (this.dashboardItems) {
      const itemWithSameNameAsTitle = this.dashboardItems.find(item => {
        return item.title === title;
      });

      return itemWithSameNameAsTitle ? itemWithSameNameAsTitle.count : 0;
    }

    return 0;
  }

}
