import { LoaderService } from './../../shared/services/loader.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styles: []
})
export class LoaderComponent implements OnInit {
  isLoading: boolean;

  constructor(private _loaderService: LoaderService) { }

  ngOnInit() {
    this._loaderService.loaderStatus().subscribe((value) => {
      this.isLoading = value;
    });
  }

}
