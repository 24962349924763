import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { SpinnerComponent } from "../components/spinner/spinner.component";

@Directive({
  selector: "[spinner]",
})
export class SpinnerDirective {
  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<any>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SpinnerComponent
    );
  }

  private message: string;

  @Input("spinner")
  set showSpinner(spinning: boolean) {
    this.container.clear();

    if (spinning) {
      this.container.createEmbeddedView(this.template);
      this.spinnerComponent = this.container.createComponent(
        this.componentFactory
      );
    } else {
      this.container.createEmbeddedView(this.template);
    }
  }

  componentFactory: ComponentFactory<SpinnerComponent>;
  spinnerComponent: ComponentRef<SpinnerComponent>;

  ngOnInit(): void { }

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    event.stopPropagation();
  }

  @HostListener("mousedown", ["$event"])
  public onMouseDown(event: any): void {
    event.stopPropagation();
  }

  @HostListener("mouseup", ["$event"])
  public onMouseUp(event: any): void {
    event.stopPropagation();
  }


}
