import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  transform(value: string, type: string = 'jpg'): any {
    if (value) {
      return `${environment.serverUrl}/media/${value}.${type}`;
    } else {
      return null;
    }
  }

}
