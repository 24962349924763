<div class="modal-container modal-confirm" *ngIf="show">
    <div class="modal modal-width-m">
        <div class="modal-header">Warning</div>
        <div class="modal-content">
            <div class="modal-article">
                <p>
                    Changes aren't saved. Do you wish to save the changes?
                </p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn-grey" (click)="answer(false)">No</button>
            <button type="button" class="btn-blue" (click)="answer(true)">Yes</button>
        </div>
    </div>
</div>