import { CollectionDetailsModel } from './../models/collections/collection-details-model';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { PaginationModel } from './../models/pagination/pagination-model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryModel } from '../models/query/queryModel';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  private resource = `${environment.serverUrl}/collection`;

  constructor(private http: HttpClient) { }

  getCollectionsP(queryModel: QueryModel): Observable<PaginationModel<CollectionModel>> {
    const url = `${this.resource}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getCollections(): Observable<CollectionModel[]> {
    const url = `${this.resource}`;
    return this.http.get<CollectionModel[]>(url);
  }

  getCollection(id: number): Observable<CollectionDetailsModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<CollectionDetailsModel>(url);
  }

  createCollection(collection: CollectionDetailsModel): Observable<any> {
    return this.http.post<any>(this.resource, collection);
  }

  deleteCollection(id: number) {
    const url = `${this.resource}/${id}`;
    return this.http.delete<any>(url);
  }
}
