import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  routerUrl: string = null;
  _subjectAnswer = new Subject<boolean>();
  _subjectShow = new Subject<boolean>();

  constructor(
    private router: Router,
  ) { }

  getAnswer(): Observable<boolean> {
    return this._subjectAnswer.asObservable();
  }

  getShowModal(): Observable<boolean> {
    return this._subjectShow.asObservable();
  }

  answer(value: boolean) {
    if (!value) {
      this.router.navigate([this.routerUrl]);
    } else {
      this._subjectAnswer.next(value);
    }
  }

  show(value: boolean, url: string) {
    this._subjectShow.next(value);
    this.routerUrl = url;
    this.router.navigateByUrl(this.router.url, { skipLocationChange: true });
  }
}
