import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit, OnDestroy {

  @ViewChild('carousel', { static: true }) carousel: ElementRef;
  @Input() showPagination: boolean;
  @Input() slideSpeed: number = 1; // seconds
  @Input() intervalDuration: number = 10000; // seconds
  @Input() margin: number = 20; // px

  numberOfCarouselItems: number[] = [];
  curentCarouselItem: number = 1;
  positive: boolean = true;
  timeLeft: number = this.intervalDuration;
  interval;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.numberOfCarouselItems = Array(this.carousel.nativeElement.childElementCount).fill(1).map((x, i) => i + 1);
    this.cdr.detectChanges();
    this.carousel.nativeElement.children[0].style.transition = `all ${this.slideSpeed}s ease-in-out`;
    const corouselItems = this.carousel.nativeElement.children;
    [...corouselItems].forEach(corouselItem => {
      corouselItem.style.marginRight = `${20}px`;
      corouselItem.style.minWidth = `100%`;
      corouselItem.style.width = `100%`;
    });
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.controler();
        this.timeLeft = this.intervalDuration;
      }
    }, 1000)
  }

  controler() {
    if (this.curentCarouselItem == this.numberOfCarouselItems.length) {
      this.positive = false;
    }
    else if (this.curentCarouselItem == 1) {
      this.positive = true;
    }

    if (this.positive) {
      this.curentCarouselItem++;
    }
    else {
      this.curentCarouselItem--;
    }
    this.carousel.nativeElement.children[0].style.marginLeft = `calc(-${this.curentCarouselItem * 100 - 100}% - ${this.curentCarouselItem * this.margin - this.margin}px) `;
  }


  goTo(pageNumber: number) {
    this.timeLeft = this.intervalDuration;
    this.curentCarouselItem = pageNumber;
    this.carousel.nativeElement.children[pageNumber - 1].classList.add("banner-card-active");
    this.carousel.nativeElement.children[0].style.marginLeft = `calc(-${this.curentCarouselItem * 100 - 100}% - ${this.curentCarouselItem * this.margin - this.margin}px) `;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
