import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable, ElementRef } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import 'rxjs/add/operator/do'
import { LoaderService } from '../services/loader.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    public _notificationService: NotificationsService,
    private _loaderService: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loaderService.activeLoader(request.url);
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
         
          this._loaderService.deactiveLoader(request.url);
     
         let errorMessage: any;
         if (request.url.includes('mazaya-payment/createPayment')) {
        
           errorMessage = this.createtabbyErrorMessage(error);
           this._notificationService.setTabbyErrorMessage(errorMessage);
         } else {
       
           errorMessage = this.createErrorMessage(error);
         }
          if (typeof errorMessage === "string") {
        
            // this._notificationService.error('Error', errorMessage);
          }else{
            // this._notificationService.error('Error', errorMessage.userMessage);
          }
          return throwError(errorMessage);
        })
      )
      .do(event => {
        if (event instanceof HttpResponse) {
          this._loaderService.deactiveLoader(request.url);
        }
      })

  }

  private createErrorMessage(error: HttpErrorResponse): string {
    const errorContentDef = "There was an error. Please contact support desk.";
    const errorContent = error.error?.Message || error.error;
    const isHtmlErrorMessage = this.isHtmlError(errorContent);

    if (isHtmlErrorMessage) {
      return errorContentDef;
    }

    return errorContent;
  }
  private createtabbyErrorMessage(error: HttpErrorResponse): string {
    const errorContentDef = "There was an error. Please contact support desk.";
    const errorContent = error.error?.data || error.error;
    const isHtmlErrorMessage = this.isHtmlError(errorContent);
  
    if (isHtmlErrorMessage) {
      return errorContentDef;
    }
  
   
    switch (errorContent) {
      case 'order_amount_too_high':
        return `This purchase is above your current spending limit with Tabby, try a smaller cart or use another payment method.<br>
                قيمة الطلب تفوق الحد الأقصى المسموح به حاليًا مع تابي. يُرجى تخفيض قيمة السلة أو استخدام وسيلة دفع أخرى.`;
      case 'order_amount_too_low':
        return `The purchase amount is below the minimum amount required to use Tabby, try adding more items or use another payment method.<br>
                قيمة الطلب أقل من الحد الأدنى المطلوب لاستخدام خدمة تابي. يُرجى زيادة قيمة الطلب أو استخدام وسيلة دفع أخرى.`;
      case 'not_available':
        return `Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.<br>
                نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.`;
      default:
        return errorContent; 
    }
  }
  

  private isHtmlError(errorContent: string) {
    const regexIsHtml = /<html/;
    return regexIsHtml.test(errorContent);
  }
}




