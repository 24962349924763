import { QueryModel } from "./../shared/models/query/queryModel";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {
  PageHistoryStateModel,
  SaveHistory,
  CheckUrl,
  DeleteHistory,
  ChangeSearch,
  ChangeFilter,
  ChangeSort,
  ChangePage,
  ResetPage,
  SaveAllHistory,
  DeleteItemHistory,
} from "./page-history-state-model";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { PaginationModel } from "../shared/models/pagination";

@State<PageHistoryStateModel>({
  name: "pageHistory",
  defaults: {
    query: new QueryModel(),
    url: null,
    searchPlaceholder: 'Search Offers',
    selectedItemId: null,
    page: null,
    scrollY: null,
  },
})
@Injectable()
export class PageHistoryState {
  constructor(private _router: Router) { }

  private updatePageProperties(page: PaginationModel<any>, selectedItemId: number): PaginationModel<any> {
    page.list = page.list.filter(item => item.id != selectedItemId);
    page.totalPageCount = this.checkPageIndex(page.list.length, page.pageIndex);
    page.pageIndex = this.checkPageIndex(page.list.length, page.pageIndex);
    page.totalCount -= 1;

    return page;
  }

  private checkPageIndex(listCount: number, pageIndex: number): number {
    if (pageIndex == 1) return pageIndex;

    return listCount == 0 ? pageIndex - 1 : pageIndex;
  }

  @Selector()
  static query(state: PageHistoryStateModel): QueryModel {
    return state.query;
  }

  // TO DO DELETE
  @Selector()
  static filterActive(state: PageHistoryStateModel): boolean {
    return null;
  }

  // TO DO DELETE
  @Selector()
  static check(state: PageHistoryStateModel, s: string): boolean {
    return null;
  }

  @Action(SaveHistory)
  saveHistory(ctx: StateContext<PageHistoryStateModel>, action: SaveHistory) {
    ctx.setState({
      query: action.query,
      url: action.url,
      searchPlaceholder: 'Search Offers'
    });
  }

  @Action(SaveAllHistory)
  saveAllHistory(ctx: StateContext<PageHistoryStateModel>, action: SaveAllHistory) {
    ctx.setState({
      query: action.query,
      url: action.url,
      searchPlaceholder: 'Search Offers',
      selectedItemId: action.selectedItemId,
      page: action.page,
      scrollY: action.scrollY,
    });
  }

  @Action(DeleteHistory)
  deleteHistory(ctx: StateContext<PageHistoryStateModel>) {

    let placeholderText = this.getPlaceholderText();

    ctx.setState({
      query: new QueryModel(),
      url: null,
      searchPlaceholder: placeholderText
    });
  }

  @Action(DeleteItemHistory)
  deleteItemHistory(ctx: StateContext<PageHistoryStateModel>, action: DeleteItemHistory) {
    let state = _.cloneDeep(ctx.getState());
    let pageCopy = state.page;
    let query = state.query;
    pageCopy = this.updatePageProperties(pageCopy, action.selectedItemId);
    query.page = pageCopy.pageIndex;
    ctx.patchState({
      query: query,
      page: pageCopy,
      selectedItemId: null
    });
  }

  @Action(CheckUrl)
  checkUrl(ctx: StateContext<PageHistoryStateModel>, action: CheckUrl) {
    let searchPlaceholder = this.getPlaceholderText();

    if (this._router.url != action.url) {
      ctx.setState({
        query: new QueryModel(),
        url: null,
        searchPlaceholder: 'searchPlaceholder'
      });
    }
    else {
      const state = _.cloneDeep(ctx.getState());
      state.searchPlaceholder = searchPlaceholder;
      ctx.setState(state);
    }
  }

  getPlaceholderText(): string {
    if (this._router.url.includes('offers/assigned')) {
      return 'Search Assigned Offers'
    }
    else if (this._router.url.includes('offers/my-offers')) {
      return 'Search My Offers'
    }
    else if (this._router.url.includes('offers/all')) {
      return 'Search All Offers'
    }
    else if (this._router.url.includes('offers/weekend')) {
      return 'Search Weekend Offers'
    }
    else if (this._router.url.includes('offers/favorites')) {
      return 'Search Favorites Offers'
    }
    else if (this._router.url.includes('offers/assigned')) {
      return 'Search Assigned Offers'
    }
    else if (this._router.url.includes('collections')) {
      return 'Search Collections'
    }
    else if (this._router.url.includes('categories')) {
      return 'Search Categories'
    }
    else if (this._router.url.includes('focal-points')) {
      return 'Search All Offers'
    }
    else if (this._router.url.includes('suppliers/all')) {
      return 'Search Suppliers'
    }
    else if (this._router.url.includes('suppliers/pending')) {
      return 'Search Pending Suppliers'
    }
    else if (this._router.url.includes('suppliers/inactive')) {
      return 'Search Inactive Suppliers'
    }
    else if (this._router.url.includes('suppliers/missing')) {
      return 'Search Missing License Suppliers'
    }
    else if (this._router.url.includes('administration/buyers')) {
      return 'Search Buyers'
    }
    else if (this._router.url.includes('administration/adnoc-users')) {
      return 'Search Adnoc Users'
    }
    else if (this._router.url.includes('administration/tags')) {
      return 'Search Tags'
    }
    else if (this._router.url.includes('administration/offers-rating')) {
      return 'Search Comment'
    }
    else if (this._router.url.includes('roadshows/all')) {
      return 'Search Roadshows'
    }
    else if (this._router.url.includes('roadshows-administration/planning')) {
      return 'Search Roadshows'
    }
    else if (this._router.url.includes('roadshows-administration/offers')) {
      return 'Search Offers'
    }
    else if (this._router.url.includes('roadshows-administration/proposals')) {
      return 'Search Proposals'
    }
    else if (this._router.url.includes('administration/user-invitation')) {
      return 'Search Invitations'
    }
    else if (this._router.url.includes('administration/mail-storage')) {
      return 'Search Mails'
    }
    else if (this._router.url.includes('surveys/admin')) {
      return 'Search Surveys'
    }
    else if (this._router.url.includes('surveys/my-survey')) {
      return 'Search My Surveys'
    }
    else {
      return 'Search Offers'
    }
  }

  @Action(ChangeSearch)
  changeSearch(ctx: StateContext<PageHistoryStateModel>, action: ChangeSearch) {
    const state = _.cloneDeep(ctx.getState());
    state.query.filter.keyword = action.search;
    state.query.page = 1;
    ctx.setState(state);

    if (this._router.url.includes('offers/assigned')) {
      this._router.navigate(["offers/assigned"]);
    }
    else if (this._router.url.includes('offers/my-offers')) {
      this._router.navigate(["offers/my-offers"]);
    }
    else if (this._router.url.includes('offers/all')) {
      this._router.navigate(["offers/all"]);
    }
    else if (this._router.url.includes('offers/weekend')) {
      this._router.navigate(["offers/weekend"]);
    }
    else if (this._router.url.includes('offers/favorites')) {
      this._router.navigate(["offers/favorites"]);
    }
    else if (this._router.url.includes('offers/assigned')) {
      this._router.navigate(["offers/assigned"]);
    }
    else if (this._router.url.includes('collections')) {
      this._router.navigate(["collections"]);
    }
    else if (this._router.url.includes('categories')) {
      this._router.navigate(["categories"]);
    }
    else if (this._router.url.includes('focal-points')) {
      this._router.navigate(["focal-points"]);
    }
    else if (this._router.url.includes('suppliers/all')) {
      this._router.navigate(["suppliers/all"]);
    }
    else if (this._router.url.includes('suppliers/pending')) {
      this._router.navigate(["suppliers/pending"]);
    }
    else if (this._router.url.includes('suppliers/inactive')) {
      this._router.navigate(["suppliers/inactive"]);
    }
    else if (this._router.url.includes('administration/buyers')) {
      this._router.navigate(["administration/buyers"]);
    }
    else if (this._router.url.includes('administration/adnoc-users')) {
      this._router.navigate(["administration/adnoc-users"]);
    }
    else if (this._router.url.includes('administration/tags')) {
      this._router.navigate(["administration/tags"]);
    }
    else if (this._router.url.includes('administration/offers-rating')) {
      this._router.navigate(["administration/offers-rating"]);
    }
    else if (this._router.url.includes('roadshows/all')) {
      this._router.navigate(["roadshows/all"]);
    }
    else if (this._router.url.includes('roadshows-administration/planning')) {
      this._router.navigate(["roadshows-administration/planning"]);
    }
    else if (this._router.url.includes('roadshows-administration/offers')) {
      this._router.navigate(["roadshows-administration/offers"]);
    }
    else if (this._router.url.includes('roadshows-administration/proposals')) {
      this._router.navigate(["roadshows-administration/proposals"]);
    }
    else if (this._router.url.includes('administration/user-invitation')) {
      this._router.navigate(["administration/user-invitation"]);
    }
    else if (this._router.url.includes('administration/mail-storage')) {
      this._router.navigate(["administration/mail-storage"]);
    }
    else if (this._router.url.includes('surveys/admin')) {
      this._router.navigate(["surveys/admin"]);
    }
    else if (this._router.url.includes('surveys/my-survey')) {
      this._router.navigate(["surveys/my-survey"]);
    }
    else if (this._router.url.includes('offers/reported')) {
      this._router.navigate(["offers/reported"]);
    }
    else {
      this._router.navigate(["offers/search"]);
    }
  }

  @Action(ChangeFilter)
  changeFilter(ctx: StateContext<PageHistoryStateModel>, action: ChangeFilter) {
    const state = _.cloneDeep(ctx.getState());
    state.query.filter = action.filter;
    state.query.page = 1;
    ctx.setState(state);
  }

  @Action(ChangeSort)
  changeSort(ctx: StateContext<PageHistoryStateModel>, action: ChangeSort) {
    const state = _.cloneDeep(ctx.getState());
    state.query.sort = action.sort;
    state.query.page = 1;
    ctx.setState(state);
  }

  @Action(ChangePage)
  changePage(ctx: StateContext<PageHistoryStateModel>, action: ChangePage) {
    const state = _.cloneDeep(ctx.getState());
    state.query.page = action.page;
    ctx.setState(state);
  }

  @Action(ResetPage)
  resetPage(ctx: StateContext<PageHistoryStateModel>, action: ResetPage) {
    const state = _.cloneDeep(ctx.getState());
    ctx.setState({
      query: new QueryModel(),
      url: null,
      searchPlaceholder: state.searchPlaceholder
    });
  }
}
