<div class="home-dashboard-container" *ngIf="dashboardItems">
    <div class="header">
        <div class="title-box">
            <div class="title">Dashboard</div>
            <div class="sub-title">Partner admin</div>
        </div>
        <div class="tools-box">
        </div>
    </div>
    <div class="content">
        <div class="section">
            <div class="section_title">Offers</div>
            <div class="section_content">
                <div class="dashboard-card" routerLink="offers/all">
                    <div class="dashboard-card_title">All Offers</div>
                    <div class="dashboard-card_subtitle">All Offer</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">{{getCountFromTitle('All Offers')}}</div>
                </div>
                <div class="dashboard-card" routerLink="offers/my-offers">
                    <div class="dashboard-card_title">My Offers</div>
                    <div class="dashboard-card_subtitle">All My Offers</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">{{getCountFromTitle('My Offers')}}</div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section_title">Focal Points</div>
            <div class="section_content">
                <div class="dashboard-card" routerLink="/focal-points">
                    <div class="dashboard-card_title">Focal Points</div>
                    <div class="dashboard-card_subtitle">All focal points</div>
                    <div class="dashboard-card_counter dashboard-icon-users">
                        {{getCountFromTitle('All Focal Points')}}</div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section_title">Roadshows</div>
            <div class="section_content">
                <div class="dashboard-card" routerLink="/roadshows-administration/planning">
                    <div class="dashboard-card_title">Roadshows</div>
                    <div class="dashboard-card_subtitle">My Roadshows</div>
                    <div class="dashboard-card_counter dashboard-icon-roadshow">
                        {{getCountFromTitle('My Roadshows')}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
    </div>
</div>