import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationModalService } from '../../services/confirmation-modal.service';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: []
})
export class ConfirmationModalComponent implements OnInit {
  private sub = new Subscription();
  private _show: boolean = false;
  private _result: Promise<any>;
  private _answer: boolean;

  constructor(private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    this.sub.add(
      this.confirmationModalService.getShowModal().subscribe(res => {
        this.show = res;
      })
    );
  }

  set show(value: boolean) {
    this._show = value;
  }

  get show() {
    return this._show;
  }

  get result(): Promise<any> {
    return this._result;
  }


  answer(value: boolean) {
    this._answer = value;
    this.show = false;
    this.confirmationModalService.answer(value);
  }
}
