import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MazayaEcardDetail } from 'src/app/shared/models/mazaya-plus/membership-details-model';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-user-cards',
  templateUrl: './user-cards.component.html',
  styleUrls: ['./user-cards.component.scss']
})
export class UserCardsComponent {
  @Input() selectedEmail: string;
  @Output() goBack = new EventEmitter();
  membershipDetails: MazayaEcardDetail[]
  isFlippedArray: boolean[] = [];
  constructor(
    private _adminService: AdminService,
  ) { }
  ngOnInit() {
    this.getCards();
  }
  getCards() {

    let emailId=encodeURIComponent(this.selectedEmail)
    this._adminService.getEcardList(emailId).subscribe((res) => {
      this.membershipDetails = res.data
    })
  }
  encodeString(value: string): string {
    return encodeURIComponent(value).replace(/%2B/g, '+');
  }
  toggleCard(index: number): void {
    this.isFlippedArray[index] = !this.isFlippedArray[index];
  }
  goback(){
    this.goBack.emit()
  }
}
