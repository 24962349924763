import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { HomeService } from './services';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxTextOverflowClampModule } from 'ngx-text-overflow-clamp';
import {
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from 'ng-lazyload-image';
import { HomeAdminComponent } from './pages/home/home-admin/home-admin.component';
import { HomeSupplierComponent } from './pages/home/home-supplier/home-supplier.component';
import { HomeReviewerComponent } from './pages/home/home-reviewer/home-reviewer.component';
import { HomeBuyerComponent } from './pages/home/home-buyer/home-buyer.component';
import { HomeSupplierAdminComponent } from './pages/home/home-supplier-admin/home-supplier-admin.component';
import { HomeBannerComponent } from './pages/home/home-buyer/home-banner/home-banner.component';
import { HomeCollectionsComponent } from './pages/home/home-buyer/home-collections/home-collections.component';
import { HomeNirvanaComponent } from './pages/home/home-nirvana/home-nirvana.component';
import { ExcelService } from 'src/app/shared/services/exportExcel.service';
import { HomeMazayaPlusComponent } from './pages/home/home-mazaya-plus/home-mazaya-plus.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { UserCardsComponent } from './pages/home/home-mazaya-plus/component/user-cards/user-cards.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeAdminComponent,
    HomeSupplierComponent,
    HomeReviewerComponent,
    HomeBuyerComponent,
    HomeSupplierAdminComponent,
    HomeBannerComponent,
    HomeCollectionsComponent,
    HomeNirvanaComponent,
    HomeMazayaPlusComponent,
    UserCardsComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    NgxTextOverflowClampModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
  ],
  providers: [
    HomeService,ExcelService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  ],
})
export class HomeModule { }
