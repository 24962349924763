import { QueryModel } from './../shared/models/query/queryModel';
import { PaginationModel } from '../shared/models/pagination/pagination-model';

export interface PageHistoryStateModel {
  query: QueryModel | null,
  url: string | null,
  searchPlaceholder?: string | null,
  selectedItemId?: number | null,
  page?: PaginationModel<any> | null,
  scrollY?: number | null,
}

export class SaveHistory {
  static readonly type = '[PageHistory] SaveHistory';
  constructor(public query, public filterActive, public url) { }
}

export class SaveAllHistory {
  static readonly type = '[PageHistory] SaveAllHistory';
  constructor(
    public query,
    public url,
    public selectedItemId,
    public page,
    public scrollY) { }
}

export class DeleteHistory {
  static readonly type = '[PageHistory] DeleteHistory';
  constructor() { }
}

export class DeleteItemHistory {
  static readonly type = '[PageHistory] DeleteItemHistory';
  constructor(public selectedItemId) { }
}

export class CheckUrl {
  static readonly type = '[PageHistory] CheckUrl';
  constructor(public url) { }
}

export class ChangeSearch {
  static readonly type = '[PageHistory] ChangeSearch';
  constructor(public search) { }
}

export class ChangeFilter {
  static readonly type = '[PageHistory] ChangeFilter';
  constructor(public filter) { }
}

export class ChangeSort {
  static readonly type = '[PageHistory] ChangeSort';
  constructor(public sort) { }
}

export class ChangePage {
  static readonly type = '[PageHistory] ChangePage';
  constructor(public page) { }
}

export class ResetPage {
  static readonly type = '[PageHistory] ResetPage';
  constructor() { }
}


