import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { timer, EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserNotificationsService } from 'src/app/shared/services/user-notification.service';
import { UserNotification } from 'src/app/shared/models/notifications/user-notification';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: []
})
export class UserNotificationsComponent implements OnInit {
  notifications: UserNotification[] = [];
  notificationsCount: number = 0;
  notificationModal: boolean;
  roles: string[] = [];

  constructor(private _userNotificationService: UserNotificationsService,
    private _router: Router, private store$: Store) { }

  ngOnInit() {
    this.loadAuth();
    this.getNotificationCount();
    this.initNotificationCount();
  }

  loadAuth() {
    this.store$.select(AuthState.roles).subscribe((roles) => {
      this.roles = roles;
    });
  }

  initNotificationCount() {
    timer(0, 10 * 1000).pipe(
      mergeMap(_ => this.getNotificationCount())
    ).subscribe((notificationCount: number) => {
      this.notificationsCount = notificationCount;
    });
  }

  getNotificationCount(): Observable<number> {
    this.loadAuth();
    if (this.roles.length > 0) {
      return this._userNotificationService.getNotificationsCount();
    } else {
      return EMPTY;
    }
  }

  acknowledge(notification: UserNotification) {
    this._userNotificationService.acknowledgeNotification(notification.id).subscribe(() => {
      this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
      this.notificationsCount = this.notifications.length;
    });

    if (notification.url) {
      this.redirectTo(notification.url);
    }
  }

  acknowledgeAll() {
    this._userNotificationService.acknowledgeAllNotification().subscribe(() => {
      this.notifications = [];
      this.notificationsCount = this.notifications.length;
    });
  }

  getNotifications() {
    this._userNotificationService.getNotifications().subscribe(notifications => {
      this.notifications = [];
      notifications.map(notification => {
        let date = new Date(notification.createdOn);
        let dateDifference = new Date((Date.now() - date.getTime())).getTime();

        // days - 86400000 milliseconds
        // hours - 3600000 milliseconds
        // minutes - 60000 milliseconds
        if (dateDifference >= 86400000) {
          notification.createdBefore = (Math.round(Math.abs(dateDifference / 86400000))) + " days ago";
        } else if (dateDifference < 86400000 && dateDifference > 3600000) {
          notification.createdBefore = (Math.round(Math.abs(dateDifference / 3600000))) + " hours ago";
        } else if (dateDifference < 3600000 && dateDifference > 60000) {
          notification.createdBefore = (Math.round(Math.abs(dateDifference / 60000))) + " minutes ago";
        } else {
          notification.createdBefore = "Just now";
        }
        this.notifications.push(notification);
      });
      this.notificationsCount = this.notifications.length;
    });
    this.notificationModal = true;
  }

  redirectTo(uri: string) {
    let uriArray: string[] = uri.split('/');
    let url: string = '/';
    // From 3rd index of url relative path begins
    // This is only temporary solution for presentation
    // TODO: Change this (See what's the problem with router)
    for (let i = 3; i < uriArray.length; i++) {
      url += uriArray[i] + '/';
    }


    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this._router.navigate([url]));
  }

  daysBetween(date: Date) {
    const oneDay = 1000 * 60 * 60 * 24;
    let difference = Math.abs(Date.now() - date.getTime());

    return Math.round(difference / oneDay);
  }

}
