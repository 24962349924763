export class SortModel {
  type: string;
  order: order;

  constructor() {
    this.type = 'date'
    this.order = order.DESC;
  }
}

export enum order {
  ASC = 0,
  DESC = 1,
}
