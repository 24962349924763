import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceCapabilitiesService {

  constructor(private deviceService: DeviceDetectorService) { }

  public allowImageDeferLoad() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    return deviceInfo && deviceInfo.browser && deviceInfo.browser.indexOf('IE') < 0;
  }
}
