import { SuggestionsListComponent } from './../features/suggestions/suggestions-list/suggestions-list.component';
import { AreaPipe } from './pipes/area.pipe';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { CompaniesService } from './services/companies.service';
import { OfferService } from './../features/offers/services/offer.service';
import { NotificationService } from './../core/services/notification.service';
import { AuthorizedDirective } from "./directives/authorized.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedRoutingModule } from "./shared-routing.module";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { ModalDirective } from "./directives/modal.directive";
import { EditorComponent } from "./components/editor/editor.component";
import { CKEditorModule } from "ckeditor4-angular";
import { CloseModalDirective } from "./directives/close-modal.directive";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SpinnerDirective } from "./directives/spinner.directive";
import { AmpPipe } from "./pipes/amp.pipe";
import { ImgPipe } from "./pipes/img.pipe";
import { DefaultValuePipe } from "./pipes/default-value.pipe";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { CategoriesService } from './services/categories.service';
import { OffersService } from './services/offers.service';
import { AdminService } from './services/admin.service';
import { FilterComponent } from './components/filter/filter.component';
import { SortComponent } from './components/sort/sort.component';
import { OfferFiltersComponent } from './elements/offer-filters/offer-filters.component';
import { OfferListComponent } from './elements/offer-list/offer-list.component';
import { OfferSortComponent } from './elements/offer-sort/offer-sort.component';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DocumentPipe } from './pipes/document.pipe';
import { TermsAndConditionService } from './services/terms-and-condition.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ShareFacebookButtonComponent } from './components/social-buttons/facebook/share-facebook-button/share-facebook-button.component';
import { TweetTwitterButtonComponent } from './components/social-buttons/twitter/tweet-twitter-button/tweet-twitter-button.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from './services/confirmation-modal.service';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MembershipRedirectionComponent } from './components/membership-redirection/membership-redirection.component';
import { DecimalPipe } from './pipes/decimal.pipe';
import { FileDragAndDropDirective } from './directives/file-drag-and-drop.directive';

@NgModule({
    declarations: [
        //Components
        CarouselComponent,
        NotificationsComponent,
        EditorComponent,
        PaginationComponent,
        SpinnerComponent,
        // Pipes
        AmpPipe,
        AreaPipe,
        ImgPipe,
        DefaultValuePipe,
        DocumentPipe,
        DecimalPipe,
        // Directives
        ModalDirective,
        AuthorizedDirective,
        CloseModalDirective,
        SpinnerDirective,
        OfferFiltersComponent,
        OfferListComponent,
        OfferSortComponent,
        SortComponent,
        FilterComponent,
        ShareFacebookButtonComponent,
        TweetTwitterButtonComponent,
        ConfirmationModalComponent,
        DebounceClickDirective,
        MembershipRedirectionComponent,
        FileDragAndDropDirective,
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        FormsModule,
        CKEditorModule,
        ReactiveFormsModule,
        FormsModule,
        LazyLoadImageModule,
        NgxSliderModule,
        // Data picker Input
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // Tel Input
        NgxIntlTelInputModule,
        NgxTrimDirectiveModule
    ],
    exports: [
        // Components
        CarouselComponent,
        EditorComponent,
        PaginationComponent,
        SpinnerComponent,
        NotificationsComponent,
        SortComponent,
        FilterComponent,
        OfferFiltersComponent,
        OfferListComponent,
        OfferSortComponent,
        ShareFacebookButtonComponent,
        TweetTwitterButtonComponent,
        ConfirmationModalComponent,
        // Pipes
        AmpPipe,
        ImgPipe,
        AreaPipe,
        DefaultValuePipe,
        DocumentPipe,
        DecimalPipe,
        // Directives
        AuthorizedDirective,
        ModalDirective,
        CloseModalDirective,
        SpinnerDirective,
        DebounceClickDirective,
        // Data picker Input
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // Select Input
        NgSelectModule,
        FormsModule,
        // Slider Input
        NgxSliderModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        // Image Cropper
        ImageCropperModule,
        // Tel Input
        NgxIntlTelInputModule,
        NgxTrimDirectiveModule,
        FileDragAndDropDirective
    ],
    providers: [
        NotificationService,
        ConfirmationModalService,
        OffersService,
        CategoriesService,
        CompaniesService,
        AdminService,
        TermsAndConditionService,
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
    ]
})
export class SharedModule { }
