import { CommentModel } from './../../../shared/models/other/comment/comment-model';
import { OfferPostModel } from './../../../shared/models/offers/offer-post-model';
import { TagModel } from './../../../shared/models/tags/tag-model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfferModel } from 'src/app/shared/models';
import { PaginationModel } from 'src/app/shared/models/pagination/pagination-model';
import { SearchOffersModel } from 'src/app/shared/models/search/search-model';
import { LocationModel } from 'src/app/shared/models/other/location/location-model';
import { CompanyModel } from 'src/app/shared/models/companies/company-model';
import { ImageModel } from 'src/app/shared/models/other/image/image-model';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  private resource = `${environment.serverUrl}/offer`;
  constructor(private http: HttpClient) { }

  searchModel: SearchOffersModel = new SearchOffersModel();

  setOfferAsFavourite(offerId: number): Observable<any> {
    const url = `${this.resource}/favourite`;
    return this.http.post<any>(url, offerId);
  }

  getOffers(pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.resource}/page/${pageNumber}`;
    return this.http.get<any>(url);
  }

  getAllOffers(pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.resource}/all/page/${pageNumber}`;
    return this.http.get<any>(url);
  }
  renewOffer(id: number): Observable<any> {
    const url = `${this.resource}/renew/${id}`;
    return this.http.get<any>(url);
  }

  getMyOffers(pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.resource}/my-offers/page/${pageNumber}`;
    return this.http.get<any>(url);
  }

  get(id: number): Observable<OfferPostModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<any>(url);
  }

  getWeekendOffers(pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.resource}/weekend/page/${pageNumber}`;
    return this.http.get<any>(url);
  }

  getAllTags(): Observable<TagModel[]> {
    const url = `${environment.serverUrl}/tag`;
    return this.http.get<any>(url);
  }

  getSearchOffers(pageNumber: number, searchOffersModel: SearchOffersModel): Observable<PaginationModel<OfferModel>> {
    this.searchModel.searchTerm = searchOffersModel.searchTerm;
    this.searchModel.categoryIds = searchOffersModel.categoryIds;
    this.searchModel.collectionIds = searchOffersModel.collectionIds;
    this.searchModel.tagIds = searchOffersModel.tagIds;
    //this.searchModel.priceRanges = searchOffersModel.priceRanges;
    this.searchModel.ratings = [];
    this.searchModel.locations = searchOffersModel.locations;
    const url = `${this.resource}/page/${pageNumber}/search`;
    return this.http.post<any>(url, this.searchModel);
  }

  create(offer: OfferPostModel): Observable<any> {
    // Encoding mandatory because app gateway firewall sees content of ckEditor as XSS attack
    offer.company = null;
    offer.companyWebsite = btoa(unescape(encodeURIComponent(offer.companyWebsite)));
    offer.priceList = btoa(unescape(encodeURIComponent(offer.priceList)));
    offer.termsAndCondition = btoa(unescape(encodeURIComponent(offer.termsAndCondition)));
    offer.aboutCompany = btoa(unescape(encodeURIComponent(offer.aboutCompany)));
    offer.description = btoa(unescape(encodeURIComponent(offer.description)));

    let images: any[] = [];
    offer.images.forEach(img => {
      let image: ImageModel = {
        id: img.id,
        cropCoordinates: img.cropCoordinates,
        cropNGXCoordinates: img.cropNGXCoordinates,
        cover: img.cover,
        type: img.type
      };
      images.push(image);
    });

    offer.images = images;

    return this.http.post<any>(this.resource, offer);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resource}/${id}`);
  }

  getSpecificOffers(pageNumber: number): Observable<PaginationModel<OfferPostModel>> {
    return this.http.get<any>(`${this.resource}/specific/page/${pageNumber}`);
  }

  getOfferDetails(id: number): Observable<OfferPostModel> {
    return this.http.get<OfferPostModel>(`${this.resource}/specific/${id}`);
  }

  setStep(id: number, comments: CommentModel[], action: string, status: string): Observable<any> {
    let ret = {
      id: id,
      comments: comments,
      action: action,
      status: status
    }
    return this.http.post<any>(`${this.resource}/step`, ret);
  }

  getAssignedOffers(pageNumber: number): Observable<PaginationModel<OfferPostModel>> {
    return this.http.get<any>(`${this.resource}/assigned/page/${pageNumber}`);
  }

  getAssignedOffer(id: number): Observable<OfferPostModel> {
    return this.http.get<any>(`${this.resource}/assigned/${id}`);
  }

  getReviewOffers(pageNumber: number): Observable<PaginationModel<OfferPostModel>> {
    return this.http.get<any>(`${this.resource}/review/page/${pageNumber}`);
  }

  getReviewOffer(id: number): Observable<OfferPostModel> {
    return this.http.get<any>(`${this.resource}/review/${id}`);
  }

  // TO-DO: Mozda bi trebalo prebaciti servis kao zaseban
  getAllOffersLocationsBySupplier(id: string): Observable<LocationModel[]> {
    let res = { supplierId: id }
    return this.http.post<LocationModel[]>(`${environment.serverUrl}/companylocation`, res)
  }

  getAllOffersLocations(): Observable<LocationModel[]> {
    return this.http.get<LocationModel[]>(`${environment.serverUrl}/companylocation/all`)
  }

  getCompany(): Observable<CompanyModel> {
    return this.http.get<CompanyModel>(`${environment.serverUrl}/companies/all`)
  }

  getQRcode(id: number): Observable<ImageModel> {
    const url = `${this.resource}/${id}/qrCode`;
    return this.http.get<ImageModel>(url);
  }



}
