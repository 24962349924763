import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/shared/models';
import { OfferModel } from 'src/app/shared/models';
import { PaginationModel } from 'src/app/shared/models/pagination/pagination-model';

@Injectable({ providedIn: 'root' })
export class CategoriesService {

  private categories = `${environment.serverUrl}/category`;
  private offersByCategory = `${environment.serverUrl}/offer`;

  constructor(private http: HttpClient) {

  }

  getCategories(pageNumber: number): Observable<PaginationModel<CategoryModel>> {
    const url = `${this.categories}/page/${pageNumber}`;
    return this.http.get<PaginationModel<CategoryModel>>(url);
  }

  getAllCategories(): Observable<CategoryModel[]> {
    const url = `${this.categories}`;
    return this.http.get<CategoryModel[]>(url);
  }

  getOffersByCategory(id: number, pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.offersByCategory}/page/${pageNumber}/category/?id=${id}`;
    return this.http.get<PaginationModel<OfferModel>>(url);
  }

  getSpecificCategory(id: number): Observable<CategoryModel> {
    return this.http.get<CategoryModel>(`${this.categories}/specific/${id}`);
  }

  createCategory(category: CategoryModel): Observable<any> {
    return this.http.post<any>(this.categories, category);
  }

  deleteCategory(id: number): Observable<any> {
    return this.http.delete<any>(`${this.categories}/${id}`);
  }
}
