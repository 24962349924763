import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BannerModel } from 'src/app/shared/models/banners/banner.model';
import { environment } from 'src/environments/environment';
import { BrowserService } from 'src/app/shared/services/browser.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styles: [
  ]
})
export class HomeBannerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() banners: BannerModel[];

  @ViewChild('carouselbanner', { static: true }) carouselBanner: ElementRef;

  @Output() bannerReadyE: EventEmitter<any> = new EventEmitter();
  @Output() clickBannerE: EventEmitter<any> = new EventEmitter();

  sub: Subscription
  bannerImagesIterator = 1;
  // Settings
  slideSpeed: number = 1; // seconds
  intervalDuration: number = 500; // seconds
  margin: number = 20; // px
  // Carousel
  carouselPageNumber: number;
  corouselPageCurent: number = 1;
  corouselScrollLeftRight: boolean;
  timeLeft: number = this.intervalDuration;
  interval;
  intervalPause: boolean = false;
  // Other
  pageOneRady: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private browserService: BrowserService,
  ) {

    this.sub = interval(6000).subscribe((func => {
      if (this.bannerImagesIterator > this.carouselPageNumber) {
        this.bannerImagesIterator = 1;
      }
      this.clickPage(this.bannerImagesIterator);
      this.bannerImagesIterator++;
    }))
  }

  ngOnInit(): void {
    // Servis koji prati aktivnosti browser-a odnosno da li je tab sa aplikacijom 
    // fokusiran ili ne.
    this.browserService.browserActiv().subscribe(res => this.intervalPause = !res);
  }

  ngAfterViewInit(): void {
    this.carouselPageNumber = this.banners.length;
    this.cdr.detectChanges();
    this.carouselBanner.nativeElement.children[0].style.transition = `all ${this.slideSpeed}s ease-in-out`;
    const corouselPages = this.carouselBanner.nativeElement.children;
    [...corouselPages].forEach(corouselPage => {
      corouselPage.style.marginRight = `${20}px`;
    });
    this.startTimer();
  }

  /* 
  Timer poziva kontroler na prethodno odredjeno vreme.
  Uvedena je boolean intervalPause koji ako je pozitivan
  zaustavlja timer. 

  intervalPause je pozitivan ako je aplikacija, tj tab nije fokusiran
  u browser-u. Uradjeno kako se baner ne bi pomerao ako tab nije fokusiran.
  */
  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.intervalPause) {
        return
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.controler();
        this.timeLeft = this.intervalDuration;
      }
    }, 1000)
  }

  /*
  Kontroler upravlja pomeranjem banera.
  U slujaju da baner dodje do poslednje strane
  pomeranje se nastavlja u suprotnu stranu.
  */
  controler(): void {
    if (this.corouselPageCurent == this.carouselPageNumber) {
      this.corouselScrollLeftRight = false;
    }
    else if (this.corouselPageCurent == 1) {
      this.corouselScrollLeftRight = true;
    }
    if (this.corouselScrollLeftRight) {
      this.corouselPageCurent++;
    }
    else {
      this.corouselPageCurent--;
    }

    this.carouselBanner.nativeElement.children[0].style.marginLeft = `calc(-${this.corouselPageCurent * 100 - 100}% - ${this.corouselPageCurent * this.margin - this.margin}px) `;
  }

  /*
  Na pocetku vrsi se samo ocitavanje prve strane banera, tek nakon
  toga ocitavaju se ostale slike
  */
  loadImg(currentPage: number, img: string): string {
    if (currentPage == 0 || this.pageOneRady) {
      return `${environment.serverUrl}/media/${img}.jpg`;
    }
    else return 'assets/images/default/img_def.svg'
  }

  /*
  Kada se na prvom baneru ocitaju slike, kompnenta salje
  da je spremna za prikaz
  */
  bannerIsReady(pageNumber): void {
    if (pageNumber == 0) {
      this.bannerReadyE.emit()
      this.pageOneRady = true;
    }
  }

  // Actions
  clickPage(pageNumber: number): void {
    this.bannerImagesIterator = pageNumber;
    this.timeLeft = this.intervalDuration;
    this.corouselPageCurent = pageNumber;
    this.carouselBanner.nativeElement.children[0].style.marginLeft = `calc(-${this.corouselPageCurent * 100 - 100}% - ${this.corouselPageCurent * this.margin - this.margin}px) `;
  }

  clickBanner(offerId: number): void {
    this.clickBannerE.emit(offerId);
  }
  redirect(offerId: number) {
    let offer = this.banners.find(x => x.offerId == offerId)
    let url: string = "";
    if (offer.bannerUrl) {
      if (!/^http[s]?:\/\//.test(offer.bannerUrl)) {
        url += 'http://';
      }
      url += offer.bannerUrl;
      window.open(url)
    }
    else {
      this.clickBanner(offerId);
    }

  }

  // Small fix za ngFor
  numberToArray(n: number): number[] {
    return Array.from(Array(n)).map((x, i) => i);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
