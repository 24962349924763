import { LoginModel } from '../core/models';

export interface AuthStateModel {
  token: string | null;
  username: string | null;
  email: string | null;
  refresh: string | null;
  roles: Array<string> | null;
  refreshTime: Date | null;
  userId: string | null;
  companyStatus: string | null;
}


export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { username, password }) { }
}

export class UserInfo {
  static readonly type = '[Auth] Login';
  constructor(public isMazayaEligible: boolean) { }
}

export class LoginWithToken {
  static readonly type = '[Auth] LoginWithToken';
  constructor(public payload: { token, refresh }) { }
}

export class TryRefresh {
  static readonly type = '[Auth] Refresh';
  constructor() { }
}

export class DoRefresh {
  static readonly type = '[Auth] DoRefresh';
  constructor() { }
}

export class LoginSuccess {
  static readonly type = '[Auth] Login Success';
}

export class LoginError {
  static readonly type = '[Auth] Login Error';
  constructor(public payload: any) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
export class LogInfo {
  static readonly type = '[Auth] LogInfo';
}


