import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { Notification, NotificationType } from '../../models/notifications/notification';
import { ResetPage } from 'src/app/state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styles: []
})
export class NotificationsComponent implements OnInit, OnDestroy {

  constructor(private _notificationService: NotificationsService, private store$: Store) { }

  notifications: Notification[] = [];
  private _subscription = new Subscription();

  ngOnInit() {
    this._subscription.add(this._notificationService.getNotifications().subscribe(notification => {
      this.addNotification(notification)
    }))
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  refresh() {
    this.store$.dispatch(new ResetPage())
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }
}
