import { CategoryDetailsModel } from "./../models/categories/category-details-model";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { CategoryModel } from "../models/categories/category-model";
import { PaginationModel } from "../models/pagination";
import { QueryModel } from '../models/query/queryModel';

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private resource = `${environment.serverUrl}/category`;

  constructor(private http: HttpClient) { }

  getCategories(): Observable<CategoryModel[]> {
    const url = `${this.resource}`;
    return this.http.get<CategoryModel[]>(url);
  }

  getCategoriesP(queryModel: QueryModel): Observable<PaginationModel<CategoryModel>> {
    const url = `${this.resource}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getCategory(id: number): Observable<CategoryDetailsModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<CategoryDetailsModel>(url);
  }

  createCategory(category: CategoryDetailsModel): Observable<any> {
    category.image = {
      id: category.img,
      cropCoordinates: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0
      },
      cropNGXCoordinates: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0
      }
    }
    return this.http.post<any>(this.resource, category);
  }

  deleteCategory(id: number) {
    const url = `${this.resource}/${id}`;
    return this.http.delete<any>(url);
  }
}
