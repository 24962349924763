import { MembershipModel } from '../memberships/membershipModel';
import { AreaModel, LocationModel } from './../other/location/location-model';
export class FilterModel {
  categories: number[];
  collections: number[];
  tags: number[];
  status: string[];
  type: string[];
  locations: LocationModel[];
  areas: AreaModel[];
  discountFrom: number;
  discountTo: number;
  priceFrom: number;
  priceTo: number;
  ratings: string[];
  keyword: string;
  special: string;
  dateFrom: Date;
  dateTo: Date;
  roadshows: number[];
  companies: number[];
  statusEnum: number[];
  rates: string[];

  filterActiveGroup: string[];
  filterOpenGroup: string[];
  filterActive: boolean;
  memberships: MembershipModel[];

  constructor() {
    this.categories = [];
    this.collections = [];
    this.tags = [];
    this.locations = [];
    this.areas = [];
    this.status = [];
    this.type = [];
    this.statusEnum = [];
    this.discountFrom = 0;
    this.discountTo = 100;
    this.priceFrom = 1;
    this.priceTo = 100000;
    this.ratings = [];
    this.keyword = '';
    this.special = '';
    this.roadshows = [];
    this.companies = [];
    this.rates = [];
    this.dateFrom = new Date('01/01/1970');
    this.dateTo = new Date('12/31/2050');

    this.filterActiveGroup = [];
    this.filterOpenGroup = [];
    this.filterActive = false;
    this.memberships = [];
  }
}
