<!-- <div class="domains-container" id="invitations">
    <div class="header">
        <div class="title-box">
            <div class="back" (click)="goBack()">Back</div>
            <div class="title">Payment Gateway Report</div>
            <div class="sub-title">{{reportSize}} payments</div>
        </div>
        <div class="tools-box">
            <label class="req1">From:</label>
            <input [owlDateTime]="dt1" class="calender-form-to"
             [owlDateTimeTrigger]="dt1" placeholder="From date"   [(ngModel)]="fromDate" >
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>

        </div>
        <div class="tools-box">
            <label class="req1">To:</label>
            <input class="calender-form-to" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="To date" [(ngModel)]="toDate">
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>

        </div>
        <div class="tools-box">
            <label class="req1">Status:</label>
            <div class="f-select-simple" style="width: 170px;">
                <ng-select #status bindLabel="type" placeholder="Status" [clearable]="true" [(ngModel)]="selectedStatus">
                    <ng-option value="New">
                        New
                    </ng-option>
                    <ng-option value="Paid">
                        Paid
                    </ng-option>
                    <ng-option value="Payment failed">
                        Payment failed
                    </ng-option>
                </ng-select>
            </div>

        </div>
        <div class="tools-box">
            <button class="btn btn-blue" (click)="reportSearch()">
                Search
            </button>
            <button class="btn btn-blue" (click)="clearSearch()">
                Clear
            </button>
        </div>
    </div>
    
    <div class="tools-box1">
        <button class="btn btn-blue" (click)="exportReport()">
            Export
        </button>
    </div>
    <div class="n-container">
        <ul class="n-responsive-table">
            <li class="n-table-header">
                <div class="n-col n-col-1">Name</div>
                <div class="n-col n-col-2">Email</div>
                <div class="n-col n-col-3">Membership</div>
                <div class="n-col n-col-4">Amount</div>
                <div class="n-col n-col-4">Vat</div>
                <div class="n-col n-col-4">Total</div>
                <div class="n-col n-col-5">Invoice No.</div>
                <div class="n-col n-col-6">Date of<br>Purchase</div>
                <div class="n-col n-col-8">Status</div>
            </li>
            <li class="n-table-row" *ngFor="let d of payments">
                <div class="n-col n-col-1" data-label="name">{{d.username}}</div>
                <div class="n-col n-col-2" data-label="email">
                    <div class="wrap-content">{{d.email}}</div>
                </div>
                <div class="n-col n-col-3" data-label="membership">{{d.member_type}}</div>
                <div class="n-col n-col-4" data-label="amount">{{d.amount}}</div>
                <div class="n-col n-col-4" data-label="amount">{{d.vat}}</div>
                <div class="n-col n-col-4" data-label="amount">{{d.totalamount}}</div>
                <div class="n-col n-col-5" data-label="invoice_no"><a href="{{d.pdfurl}}"
                        target="_blank">{{d.invoicenumber}}</a></div>
                <div class="n-col n-col-6" data-label="dop">{{dateformat(d.purchase_date)}}</div>
                <div class="n-col n-col-8 success" data-label="success">{{d.status}}</div>
            </li>
        </ul>
    </div>
</div> -->

<div ></div>