import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SortSettingModel, SortType } from '../../models/query/sortSettingModel';
import { SortModel, order } from '../../models/query/sortModel';

@Component({
  selector: 'offer-sort',
  templateUrl: './offer-sort.component.html',
  styles: []
})
export class OfferSortComponent implements OnInit {

  @Input() sort: SortModel;
  @Input() sortSetting: SortSettingModel;
  @Output() onSortChange = new EventEmitter();

  sortType: SortType;
  dropDownModal: boolean;

  constructor() { }

  ngOnInit() {
    this.initSort();
    this.orderSort();
  }

  initSort() {
    for (var i = 0; i < this.sortSetting.types.length; i++) {
      if (this.sortSetting.types[i].mark === this.sort.type) {
        this.sortType = this.sortSetting.types[i]
        break;
      }
    }
  }

  orderSort() {
    this.sortSetting.types.sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  }

  changeSort(type: SortType, orderC: string) {


    this.sort = new SortModel();
    this.sort.type = type.mark;

    if (orderC === 'ASC') this.sort.order = order.ASC
    else this.sort.order = order.DESC

    this.sortType = type;

    this.onSortChange.emit(this.sort);
  }
}
