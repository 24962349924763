import { CollectionModel } from './../../../shared/models/collections/collection-model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/shared/models';
import { OfferModel } from 'src/app/shared/models';
import { BannerModel } from 'src/app/shared/models/banners/banner.model';
@Injectable({ providedIn: 'root' })
export class HomeService {

  private resource = `${environment.serverUrl}/home`;

  private categories = `${environment.serverUrl}/home/categories?limit=12`;

  private banners = `${environment.serverUrl}/banner?limit=10`;

  private collections = `${environment.serverUrl}/home/collections?limit=10`;

  constructor(private http: HttpClient) { }

  getOffers(limit: number): Observable<Array<OfferModel>> {
    const url = `${this.resource}/offers`;

    const httpParams = limit ?
      { params: new HttpParams().set('limit', limit.toString()) } : {};

    return this.http.get<any>(url, httpParams);
  }

  getCategories(): Observable<Array<CategoryModel>> {
    return this.http.get<Array<CategoryModel>>(this.categories);
  }

  getCollections(): Observable<Array<CollectionModel>> {
    return this.http.get<Array<CollectionModel>>(this.collections);
  }

  getBanners(): Observable<Array<BannerModel>> {
    return this.http.get<Array<BannerModel>>(this.banners);
  }

}
