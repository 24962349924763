<form class="box" [formGroup]="form" autocomplete="off">
  <div class="logo"></div>
  <label>Please provide the following details.</label>
  <div class="input-array">
    <div class="custom-file-upload">
      <input type="file" id="fileSource" [multiple]="false" placeholder="Upload Trade Licence Here"
        (change)="onFileChange($event)" />
      <label for="fileSource">{{ fileName }}</label>
      <ng-container *ngIf="!!fileValidationMessage; else requiredMessage">
        <div class="error-message error-message--upload">
          {{ fileValidationMessage }}
        </div>
      </ng-container>
      <ng-template #requiredMessage>
        <div class="error-message error-message--upload" *ngIf="
            !form.get('tradeLicence').valid &&
            form.get('tradeLicence').touched &&
            form.get('tradeLicence').errors?.required
          ">
          Trade Licence is required.
        </div>
      </ng-template>
    </div>
    <div class="input-wrapper">
      <input formControlName="tradeLicenceExpiryDate" [owlDateTime]="dateTo" [owlDateTimeTrigger]="dateTo"
        [min]="todaysDate" placeholder="Trade Licence Expiry Date" [readonly]="true" />
      <owl-date-time [pickerType]="'calendar'" #dateTo></owl-date-time>
      <div class="error-message error-message--date" *ngIf="
          !form.get('tradeLicenceExpiryDate').valid &&
          form.get('tradeLicenceExpiryDate').touched &&
          form.get('tradeLicenceExpiryDate').errors?.required
        ">
        Date is required.
      </div>
    </div>
    <button (click)="nextButton()">Next</button>
  </div>
</form>