<div class="notifications">
    <div class="notification-btn" (click)="getNotifications(); notificationModal = true"
        (clickOutside)="notificationModal = false">
        <div class="counter" *ngIf="notificationsCount > 0">{{notificationsCount}}</div>
        <div class="notification" *ngIf="notificationModal">
            <div class="notification-header">
                <div class="title">Notification</div>
                <div class="see-all" (click)="acknowledgeAll()" *ngIf="notificationsCount > 0">Clear All</div>
            </div>
            <div class="notification-content">
                <div class="notification-item" *ngFor="let notif of notifications" (click)="acknowledge(notif)">
                    <div class="item-header">
                        <div class="title">{{notif.title}}</div>
                        <div class="time">{{notif.createdBefore}}</div>
                    </div>
                    <div class="item-content">
                        {{notif.message}}
                    </div>
                </div>
                <div class="no-notifications" *ngIf="notificationsCount == 0"> There are no new notifications.</div>

            </div>
        </div>
    </div>
</div>