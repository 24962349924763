import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PaymentType, paymentTypes } from 'src/app/shared/models/paymenttypes/paymentType';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ExcelService } from 'src/app/shared/services/exportExcel.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-home-nirvana',
  templateUrl: './home-nirvana.component.html',
  styleUrls: ['./home-nirvana.component.scss']
})
export class HomeNirvanaComponent {
  payments: PaymentType[];
  data: paymentTypes;
  @ViewChild('dt1', { static: false }) dt1: ElementRef;

  fromDate: Date;
  toDate: Date;
  
  selectedStatus: string="Paid";
exportArray=[]
reportSize:number=0;

  constructor(
    private _navigationService: NavigationService,
    private _adminService: AdminService,
    private excelService: ExcelService,
    private _router: Router,
   
  ) { 
    
  }
  goBack() {
    this._navigationService.back();
  }
  ngOnInit() {
    this._router.navigate([`dashboard`]);
  
    // this.toDate = new Date(); 
    // this.fromDate=new Date();
    // this.loadPayments();

  }
  loadPayments() {
    const fromDateString: string = this.fromDate.toISOString();
    const fromDate=this.fromDate===undefined? "0001-01-01":  this.dateformat(fromDateString)
    const toDateString: string = this.toDate.toISOString();
    const toDate=this.toDate===undefined? "0001-01-01":   this.dateformat(toDateString)
    const status="Paid"
    this._adminService.getPaymentReportWithFilter(fromDate,toDate,status).subscribe((res) => {
      this.payments = res.data;
      this.reportSize=res.data.length;
      this.loadExportJson(res.data)

    })
  }
  dateformat(value: string) {
    if (value == null)
      return ""
    const date = moment(value);
    const formattedDob = date.format('YYYY-MM-DD');
    return formattedDob;
  }
  loadExportJson(result) {
    this.exportArray=[]
    for (let i = 0; i <result.length; i++) {
      const { username, email, member_type, amount, vat, totalamount, purchase_date, status,authorizationcode,invoicenumber } = result[i];
      this.exportArray.push({ User_name: username, Email: email, Membership: member_type, Amout: amount, Vat: vat, Total_Amount: totalamount, Purchase_Date: this.dateformat(purchase_date),Authorization_Code:authorizationcode,Invoice_Number:invoicenumber, Status:status });
    }
  }
  reportSearch() {
    const fromDateString: string = this.fromDate==undefined?"0001-01-01":this.fromDate.toISOString();
    const fromDate=this.fromDate===undefined? "0001-01-01":  this.dateformat(fromDateString)
    const toDateString: string = this.toDate==undefined?"0001-01-01":this.toDate.toISOString();
    const toDate=this.toDate===undefined? "0001-01-01":   this.dateformat(toDateString)
    const status=this.selectedStatus=== null ? "":  this.selectedStatus
     this._adminService.getPaymentReportWithFilter(fromDate,toDate,status).subscribe((res) => {
        this.payments = res.data;
        this.reportSize=res.data.length;
        this.loadExportJson(res.data)
     })

  }
exportReport(){
  this.excelService.exportToExcel(this.exportArray, 'exported_data')
}
clearSearch(){
  this.fromDate=undefined
  this.toDate=undefined
  this.selectedStatus=null;
  this.reportSearch();
}

}
