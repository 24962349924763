import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CKEditor5 } from "@ckeditor/ckeditor5-angular";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor4 } from "ckeditor4-angular";

@Component({
  selector: "editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements OnInit {
  @Input() data: string;
  @Input() readonly: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  config: Object;

  constructor() { }

  ngOnInit() {
    this.config = {
      readOnly: this.readonly
    }

  }

  onchange(event: CKEditor4.EventInfo) {
    this.onChange.emit(event.editor.getData());
  }
}
