import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout, ResetPage } from 'src/app/state';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styles: []
})
export class InfoComponent implements OnInit {

  role: string;

  constructor(
    private store$: Store,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.store$.dispatch(new ResetPage());
  }

  goBack() {
    this.store$.dispatch(new ResetPage());
    this.store$.dispatch(new Logout());
    this._router.navigate(['/login']);
  }

}
