import { Injectable } from "@angular/core";
import { IdentityProviderInfo } from "../models/identity-provider-info";

const IDENTITY_PROVIDERS_KEY = "IDENTITY_PROVIDERS";

@Injectable({ providedIn: "root" })
export class IdentityProviersStorageService {
  saveIdentityProviders(identityProviderInfos: IdentityProviderInfo[]) {
    const payload = JSON.stringify(identityProviderInfos || []);
    localStorage.setItem(IDENTITY_PROVIDERS_KEY, payload);
  }

  getStoredIdentityProviders(): IdentityProviderInfo[] {
    const data = localStorage.getItem(IDENTITY_PROVIDERS_KEY);
    return JSON.parse(data) || [];
  }

  clear() {
    localStorage.setItem(IDENTITY_PROVIDERS_KEY, JSON.stringify([]));
  }
}
