<div class="domains-container" id="invitations" *ngIf="!userCardView">
    <div class="header">
        <div class="title-box">
            <div class="title">Mazaya Card Update</div>
            <div class="sub-title">Please search using email</div>
        </div>
        <!-- <div class="tools-box">
            <div class="f-select-simple" style="width: 200px;">
                <input class="input-email" type="text" placeholder="Enter email" [(ngModel)]="email">
            </div>
            <div class="f-select-simple" style="width: 200px;">
                <input class="input-email" type="text" placeholder="Enter card no" [(ngModel)]="cardNo">
            </div>
            <div class="f-select-simple" style="width: 200px;">
                <input class="input-email" type="text" placeholder="Enter name" [(ngModel)]="name">
            </div>
            <button class="btn btn-blue" (click)="onSearch()">
                Search
            </button>
        </div> -->

    </div>
    <div class="grid-container">
        <div class="f-select-simple" >
            <input class="input-email" type="text" placeholder="Enter Email/Mobile Number" [(ngModel)]="email">
        </div>
        <div class="f-select-simple" >
            <input class="input-email" type="text" placeholder="Enter Card No" [(ngModel)]="cardNo">
        </div>
        <div class="f-select-simple" >
            <input class="input-email" type="text" placeholder="Enter Name" [(ngModel)]="name">
        </div>
        <button class="btn btn-blue" (click)="onSearch()">
            Search
        </button>
        
      </div>
    <div class="n-container">
        <ul class="n-responsive-table">
            <li class="n-table-header">
                <div class="n-col n-col-1">Name</div>
                <div class="n-col n-col-2">Email</div>
                <div class="n-col n-col-2">Card No.</div>
                <div class="n-col n-col-3">Relation</div>
                <div class="n-col n-col-4">Date of birth</div>
                <div class="n-col n-col-5"></div>
                <div class="n-col n-col-5"></div>

            </li>
            <li class="n-table-row" *ngFor="let item of membershipDetails; let i=index">
                <div class="n-col n-col-1" data-label="name">{{item.firstname}} {{item.lastname}}</div>
                <div class="n-col n-col-2" data-label="email">
                    <div class="wrap-content">{{item.email}}</div>
                </div>
                <div class="n-col n-col-2" data-label="card_number">
                    <div class="wrap-content">{{item.card_number}}</div>
                </div>
                <div class="n-col n-col-3" data-label="relation">{{item.relation}}</div>
                <div class="n-col n-col-4" data-label="dob">{{item.dob}}</div>
                <div class="n-col n-col-5"> <button class="btn btn-blue" (click)="onEdit(i)">
                        Edit
                    </button></div>
                    <div class="n-col n-col-5"> <button *ngIf="item.relation=='Myself' || item.relation=='myself'" class="btn btn-blue" (click)="onViewCardList(i)">
                        View Cards
                    </button></div>

            </li>
        </ul>
    </div>

</div>

<div class="modal-container" modal *ngIf="isEnableModal">
    <div class="modal modal-width-l">
        <div class="modal-header">Modify Card</div>

        <div class="modal-content">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div style="display: flex;">
                                <div class="">
                                    <div class="modify-img">
                                        <div class="file-upload">
                                            <img class="upload-image" src="assets/images/default/user_def.svg" alt=""
                                                *ngIf="!profile_image">
                                            <img class="upload-image" *ngIf="profile_image" [src]="profile_image"
                                                alt="profile">

                                            <!-- <img class="upload-image"> -->

                                        </div>
                                        <!-- <input type="file" accept=".jpg, .jpeg, .png" #fileInput style="display: none" (change)="onFileSelected($event)"> -->

                                        <input type="file" #fileInput style="display: none" accept=".jpg, .jpeg, .png"
                                            (change)="onFileSelected($event)">
                                        <button class="btn-blue" (click)="selectFile()">Change Photo</button>
                                    </div>
                                    <div class="error-validate">

                                    </div>
                                    <div class="resend-invite"  *ngIf="membershipDetails[selectedCard].relation!='Myself'">
                                        <Button  class="btn-blue"
                                            (click)="resendInvite(membershipDetails[selectedCard].email)">Resend Invitation </Button>
                                    </div>

                                </div>
                                <form #form [formGroup]="myForm">
                                    <div>
                                        <div>
                                            <fieldset>
                                                <legend>Member </legend>

                                                <div class="row-details">
                                                    <div class="column-details mr-16">
                                                        <div class="box">
                                                            <mat-form-field class="textbox-firstname">
                                                                <mat-label>First name</mat-label>
                                                                <input matInput formControlName="firstname" (input)="onInputTrim('firstname')"/>
                                                                <mat-error
                                                                    *ngIf="myForm.get('firstname').hasError('required')">
                                                                    First name is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details">
                                                        <div class="box">
                                                            <mat-form-field class="textbox-lastname">
                                                                <mat-label>Last name</mat-label>
                                                                <input matInput formControlName="lastname" (input)="onInputTrim('lastname')"/>
                                                                <mat-error
                                                                    *ngIf="myForm.get('lastname').hasError('required')">
                                                                    Last name is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="box">
                                                    <mat-form-field class="textbox-email">
                                                        <mat-label>Email</mat-label>
                                                        <input matInput formControlName="email" [readonly]="membershipDetails[selectedCard].relation=='Myself' || membershipDetails[selectedCard].relation=='myself'"/>
                                                        <mat-error *ngIf="myForm.get('email').hasError('required')">
                                                            Email is required.
                                                        </mat-error>
                                                        <mat-error *ngIf="myForm.get('email').hasError('email')">
                                                            Invalid email format.
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div class="row-details">
                                                    <div class="column-details mr-16"
                                                        *ngIf="membershipDetails[selectedCard].type==='A'">
                                                        <div class="box">
                                                            <mat-form-field class="calender">
                                                                <mat-label>Date of birth</mat-label>
                                                                <input matInput [matDatepicker]="picker" [max]="maxDate"
                                                                    formControlName="dob" (click)="picker.open()"
                                                                    [readonly]="true">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                                <mat-error>
                                                                    Date of birth is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details mr-16"
                                                        *ngIf="membershipDetails[selectedCard].type==='C'">
                                                        <div class="box">
                                                            <mat-form-field class="calender">
                                                                <mat-label>Date of birth</mat-label>
                                                                <input matInput [matDatepicker]="picker" [max]="today" [min]="maxDate"
                                                                    formControlName="dob" (click)="picker.open()"
                                                                    [readonly]="true">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                                <mat-error>
                                                                    Date of birth is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details">
                                                        <div class="box" *ngIf="membershipDetails[selectedCard].relation!='Myself'">
                                                            <mat-form-field class="relation">
                                                                <mat-label>Relation</mat-label>
                                                                <mat-select formControlName="relation" >
                                                                    <mat-option *ngFor="let relation of relations"
                                                                        [value]="relation.value">
                                                                        {{relation.viewValue}}
                                                                    </mat-option>
                                                                </mat-select>
                                                               
                                                                <mat-error
                                                                    *ngIf="myForm.get('relation').hasError('required')">
                                                                    Relationship is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="box" *ngIf="membershipDetails[selectedCard].relation=='Myself' || membershipDetails[selectedCard].relation=='myself'">
                                                            <mat-form-field class="relation">
                                                                <mat-label>Relation</mat-label>
                                                                <input  matInput formControlName="relation" [readonly]="true" >
                                                              
                                                            </mat-form-field>
                                                            
                                                        </div>
                                                    </div>

                                                </div>
                                                <!-- <div class="box">
                                                    <mat-form-field class="relation">
                                                        <mat-label>Status</mat-label>
                                                        <mat-select formControlName="relation">
                                                            <mat-option value="Active">
                                                                Active
                                                            </mat-option>
                                                            <mat-option value="Inactive">
                                                                Inactive
                                                            </mat-option>

                                                        </mat-select>
                                                
                                                    </mat-form-field>
                                                </div> -->
                                            </fieldset>
                                            <!-- <button type="button" class="update-btn" (click)="onSubmit()">Save changes</button> -->
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-blue" (click)="onUpdateDetails()">Save Changes</button>
            <button class="btn-grey" (click)="onCloseEdit()">Close</button>
        </div>
    </div>
</div>

<div class="domains-container" id="invitations" *ngIf="userCardView">
    <app-user-cards [selectedEmail]="selectedEmail" (goBack)="goBack()"></app-user-cards>

</div>