export class LocalStorage {
  static setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  static getItem(key: string): any {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
