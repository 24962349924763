import { ImageModel } from 'src/app/shared/models/other/image/image-model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { PaginationModel } from '../models/pagination';
import { OfferModel } from '../models';
import { OfferPostModel } from '../models/offers/offer-post-model';
import { QueryModel } from '../models/query/queryModel';
import { OfferRatingModel } from '../models/offers/offer-rating';
import { HttpParams } from '@angular/common/http';
import { OfferShareModel } from '../models/offers/offer-share-model';
import { OfferFavoriteModel } from '../models/offers/offer-favorite';
import { TransferOffersModel } from '../models/offers/transfer-offers';
import { OfferReportModel, OfferReportType } from '../models/offers/offer-report';
import { List } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  private resource = `${environment.serverUrl}/offer`;
  private offerRatingResource = `${environment.serverUrl}/offerrating`;
  private resourceReport = `${environment.serverUrl}/offerreport`;

  constructor(private http: HttpClient) { }

  getOffersP(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getOffers(pageNumber: number): Observable<PaginationModel<OfferModel>> {
    const url = `${this.resource}/all/page/${pageNumber}`;
    return this.http.get<any>(url);
  }

  getMyOffersP(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/my-offers/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getWeekendOffersP(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/weekend/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }
  getFavoritesOffers(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/favorites/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }
  getReportedOffer(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${environment.serverUrl}/offerreport/offers`;
    return this.http.post<any>(url, queryModel);
  }

  getSearchOffers(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/page/${queryModel.page}/search`;
    return this.http.post<any>(url, queryModel);
  }

  getCategoryffersP(queryModel: QueryModel, categoryId: number): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/page/${queryModel.page}/category/?id=${categoryId}`;
    return this.http.post<any>(url, queryModel);
  }

  getTagsffersP(queryModel: QueryModel, tagId: number): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/page/${queryModel.page}/tag/?id=${tagId}`;
    return this.http.post<any>(url, queryModel);
  }

  getCollectionOffersP(queryModel: QueryModel, collectionId: number): Observable<PaginationModel<OfferPostModel>> {
    const url = `${this.resource}/page/${queryModel.page}/collection/?id=${collectionId}`;
    return this.http.post<any>(url, queryModel);
  }


  // getAssignedOffersP(pageNumber: number): Observable<PaginationModel<OfferPostModel>> {
  //   return this.http.get<any>(`${this.resource}/assigned/page/${pageNumber}`);
  // }

  // getReviewOffersP(pageNumber: number): Observable<PaginationModel<OfferPostModel>> {
  //   return this.http.get<any>(`${this.resource}/review/page/${pageNumber}`);
  // }

  getAssignedOffers(queryModel: QueryModel): Observable<PaginationModel<OfferPostModel>> {
    return this.http.post<any>(`${this.resource}/specific/page/${queryModel.page}`, queryModel);
  }

  getOffer(id: number): Observable<OfferPostModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<any>(url);
  }

  getAssignedOffer(id: number): Observable<OfferPostModel> {
    return this.http.get<any>(`${this.resource}/assigned/${id}`);
  }

  getReviewOffer(id: number): Observable<OfferPostModel> {
    return this.http.get<any>(`${this.resource}/review/${id}`);
  }

  createOffer(offer: OfferPostModel): Observable<any> {
    // Encoding mandatory because app gateway firewall sees content of ckEditor as XSS attack
    offer.company = null;
    offer.companyWebsite = btoa(unescape(encodeURIComponent(offer.companyWebsite)));
    offer.priceList = btoa(unescape(encodeURIComponent(offer.priceList)));
    offer.termsAndCondition = btoa(unescape(encodeURIComponent(offer.termsAndCondition)));
    offer.aboutCompany = btoa(unescape(encodeURIComponent(offer.aboutCompany)));
    return this.http.post<any>(this.resource, offer);
  }

  test(): Observable<any> {
    const url = `${this.resource}/error`;
    return this.http.get<any>(url);
  }

  rateOffer(offerRating: OfferRatingModel): Observable<any> {
    return this.http.post<any>(`${this.offerRatingResource}/rating`, offerRating);
  }

  shareOffer(offerShare: OfferShareModel): Observable<any> {
    return this.http.post<any>(`${this.resource}/share`, offerShare);
  }

  reportOffer(offerReport: OfferReportModel): Observable<any> {
    return this.http.post<any>(`${this.resourceReport}`, offerReport);
  }

  getReportOfferInfo(offerReport: number): Observable<OfferReportModel[]> {
    return this.http.get<OfferReportModel[]>(`${this.resourceReport}/` + offerReport);
  }

  getReportOffer(offerReport: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.resourceReport}/isreported/` + offerReport);
  }
  resolveReport(offerReport: number): Observable<OfferReportModel[]> {
    return this.http.get<OfferReportModel[]>(`${this.resourceReport}/resolveRepor/` + offerReport);
  }
  setOfferAsFavourite(offerFavorite: OfferFavoriteModel): Observable<any> {
    const url = `${this.resource}/${offerFavorite.offerId}/favourite`;
    return this.http.post<any>(url, offerFavorite);
  }

  get(id: number): Observable<OfferPostModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<any>(url);
  }

  getQRcode(id: number): Observable<ImageModel> {
    const url = `${this.resource}/${id}/qrCode`;
    return this.http.get<ImageModel>(url);
  }

  getLatestOffers(limit: number): Observable<Array<OfferPostModel>> {
    const url = `${environment.serverUrl}/home/offers`;

    const httpParams = limit ?
      { params: new HttpParams().set('limit', limit.toString()) } : {};

    return this.http.get<any>(url, httpParams);
  }

  transferOffers(transferObject: TransferOffersModel) {
    const url = `${this.resource}/transfer-offers`;
    return this.http.post<any>(url, transferObject);
  }

}
