<div class="home-dashboard-container">
    <div class="header">
        <div class="title-box">
            <div class="title">Dashboard</div>
            <div class="sub-title">Administration</div>
        </div>
        <div class="tools-box">
        </div>
    </div>
    <div class="content">
        <div class="section">
            <div class="section_title">Offers</div>
            <div class="section_content">
                <div class="dashboard-card" routerLink="offers/all">
                    <div class="dashboard-card_title">All Offers</div>
                    <div class="dashboard-card_subtitle">All Offer</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">55</div>
                </div>
                <div class="dashboard-card" routerLink="offers/my-offers">
                    <div class="dashboard-card_title">My Offers</div>
                    <div class="dashboard-card_subtitle">All My Offers</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">32</div>
                </div>
                <div class="dashboard-card" routerLink="offers/assigned">
                    <div class="dashboard-card_title">Assigned Offers</div>
                    <div class="dashboard-card_subtitle">All Assigned Offers</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">117</div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section_title">Partners</div>
            <div class="section_content">
                <div class="dashboard-card" routerLink="suppliers/all">
                    <div class="dashboard-card_title">All Partners</div>
                    <div class="dashboard-card_subtitle">All Offer Agreements</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">14</div>
                </div>
                <div class="dashboard-card" routerLink="suppliers/pending">
                    <div class="dashboard-card_title">Pending Partners</div>
                    <div class="dashboard-card_subtitle">Pending Partners</div>
                    <div class="dashboard-card_counter dashboard-icon-offers">117</div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
    </div>
</div>