<div class="filter_box">
    <div class="filter_box_modal">
        <div class="filter_box_modal_header">
            Filters
        </div>
        <div class="filter_box_modal_content">
            <form [formGroup]="form" *ngIf="form">
                <div *ngIf="filterSetting.keyword" class="search-keyword">
                    <div class="search-icon"></div>
                    <input class="search-input" type="text" formControlName="keywordM" placeholder="Search"
                        (keydown.enter)="changeKeyword()">
                </div>
                <div *ngIf="filterSetting.categories" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('categories')">
                    <div class="criterion-header" (click)="expandOption('categories')">
                        Category
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of categories; let i = index" formArrayName="categories">
                            <div class="f-checkbox">
                                <input id="{{categories[i].title}}" type="checkbox" [formControlName]="i">
                                <label for="{{categories[i].title}}">{{categories[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.memberships" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('memberships')">
                    <div class="criterion-header" (click)="expandOption('memberships')">
                        Membership
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of memberships; let i = index"
                            formArrayName="memberships">
                            <div class="f-checkbox">
                                <input id="{{memberships[i].title}}" type="checkbox" [formControlName]="i">
                                <label for="{{memberships[i].title}}">{{memberships[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.collections" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('collections')">
                    <div class="criterion-header" (click)="expandOption('collections')">
                        Collection
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of collections; let i = index"
                            formArrayName="collections">
                            <div class="f-checkbox">
                                <input id="{{collections[i].title}}" type="checkbox" [formControlName]="i">
                                <label for="{{collections[i].title}}">{{collections[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.tags" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('tags')">
                    <div class="criterion-header" (click)="expandOption('tags')">
                        Tags
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of tags; let i = index" formArrayName="tags">
                            <div class="f-checkbox">
                                <input id="{{tags[i].title}}" type="checkbox" [formControlName]="i">
                                <label for="{{tags[i].title}}">{{tags[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.status" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('status')">
                    <div class="criterion-header" (click)="expandOption('status')">
                        Status
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of status; let i = index" formArrayName="status">
                            <div class="f-checkbox">
                                <input id="{{status[i]}}" type="checkbox" [formControlName]="i">
                                <label for="{{status[i]}}">{{status[i]}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.type" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('type')">
                    <div class="criterion-header" (click)="expandOption('type')">
                        Type
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of type; let i = index" formArrayName="type">
                            <div class="f-checkbox">
                                <input id="{{type[i]}}" type="checkbox" [formControlName]="i">
                                <label for="{{type[i]}}">{{type[i]}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.locations" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('locations')">
                    <div class="criterion-header" (click)="expandOption('locations')">
                        Locations
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of locations; let i = index" formArrayName="locations">
                            <div class="f-checkbox">
                                <input id="{{locations[i].id}}" type="checkbox" [formControlName]="i">
                                <label for="{{locations[i].id}}">{{locations[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.areas" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('areas')">
                    <div class="criterion-header" (click)="expandOption('areas')">
                        Locations
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let order of areas; let i = index" formArrayName="areas">
                            <div class="f-checkbox">
                                <input id="{{areas[i].id}}" type="checkbox" [formControlName]="i">
                                <label for="{{areas[i].id}}">{{areas[i].title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.discount" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('discount')">
                    <div class="criterion-header" (click)="expandOption('discount')">
                        Discount
                    </div>
                    <div class="criterion-options">
                        <div class="f-slider">
                            <ngx-slider formControlName="discountM" [options]="{floor: 0, ceil: 100, animate: false}"
                                (userChangeEnd)="changeDiscount()"></ngx-slider>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.price" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('price')">
                    <div class="criterion-header" (click)="expandOption('price')">
                        Price
                    </div>
                    <div class="criterion-options">
                        <div class="f-slider">
                            <ngx-slider formControlName="priceM" [options]="optionsSliderPrice"
                                (userChangeEnd)="changePrice()">
                            </ngx-slider>
                        </div>
                    </div>
                </div>
                <div *ngIf="filterSetting.rating && form.controls['ratings']" class="search-criterion"
                    [class.search-criterion-extended]="checkOptionExtended('ratings')">
                    <div class="criterion-header" (click)="expandOption('ratings')">
                        Rating
                    </div>
                    <div class="criterion-options">
                        <div class="option" *ngFor="let r of ratings; let j = index" formArrayName="ratings">
                            <div class="f-checkbox">
                                <input id="{{ratings[j]}}rating" type="checkbox" [formControlName]="j">
                                <label for="{{ratings[j]}}rating">
                                    <div class="stars">
                                        <div [style.width]="j * 20 + 20 + '%'" class="stars-positive"></div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reset">
                    <div class="collapse-btn" *ngIf="extendedOptions.length > 0" (click)="collapseFilter()">Collapse
                        Filter<span *ngIf="!oneFilter">s</span></div>
                    <div class="reset-btn" (click)="resetFilter()">Reset Filter<span *ngIf="!oneFilter">s</span></div>
                </div>
            </form>
        </div>
        <div class="filter_box_modal_footer">
            <button class="btn-blue" (click)="resetFilter()">Reset Filter<span *ngIf="!oneFilter">s</span></button>
            <button class="btn-grey" (click)="closeFilterF()">Close</button>
        </div>
    </div>
</div>