import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-registration-layout',
  templateUrl: './company-registration-layout.component.html',
  styleUrls: ['./company-registration-layout.component.scss']
})
export class CompanyRegistrationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
